import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Stack,Switch,Tooltip,Typography,Zoom,useMediaQuery} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import JumboSearch from "@jumbo/components/JumboSearch";
import ClassStudentsList from "./ClassStudentsList";
import ListRow from "../../../components/ListRow";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import {useDebouncedCallback} from "beautiful-react-hooks";
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses/JumboSearchClasses';
import { LinkStyle } from '../users/UserItem';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

export const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(Item)`
    padding: 4px 10px;
    background: #0092D0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 0.5rem;
    cursor: pointer;

    svg {
        color: white;
    }
`

export const generateYearOptions = () => {
    const arr = [];
    
    const startYear = 1980;
    const endYear = new Date().getFullYear();
    
    for (let i = endYear + 2; i >= startYear; i--) {
        arr.push(<MenuItem key={i} value={i.toString()}>{i}</MenuItem>);
    }
    
    return arr;
};

const ClassForm = () => {

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const navigate = useNavigate();
    let { state, pathname } = useLocation();

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });

        //  Redireciona se mensagem de retorno for de sucesso
        /* if(!id) {
            if(variant == 'success'){
                setTimeout(function() {
                    navigate("/app/listar-turmas")

                }, 2000)
            }
        } */
    };

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        curso_id: "",
        ano: "",
        periodicidade: "",
        dia_da_semana: "",
        horario: "",
        modo: "",
        local: "",
        grupo: "",
        quantidade_de_alunos: ""
    }

    const validationSchema = yup.object().shape({
        curso_id: yup
            .string()
            .required('Preenchimento obrigatório'),
        quantidade_de_alunos: yup.number()
            .typeError('Digite apenas números')
            .positive('Deve ser maior que zero')
    }, []);

    const { id } = useParams()
    const location = useLocation(); 
    const [courses, setCourses] = useState([]);
    const [classes, setClasses] = useState(initialValues);
    const [searchTerm, setSearchTerm] = useState(null)
    const [loading, setLoading] = useState(false)
    const [coursesInClasses, setCoursesInClasses] = useState([])

    const [addStudents, setAddStudents] = useState(null);
    const [classStudents, setClassStudents] = useState([]);

    const [isInputFocused, setIsInputFocused] = useState(false);

    const { hasPermission } = useContext(PermissionContext);

    const canCreate = hasPermission('Turmas', 'criar');
    const canEdit = hasPermission('Turmas', 'editar');
    const isDisabled = id ? !canEdit : !canCreate

    const canCreateInclude = hasPermission('Inclusão de Alunos na Turma', 'criar');
    const canEditInclude = hasPermission('Inclusão de Alunos na Turma', 'editar');
    const canView = hasPermission('Inclusão de Alunos na Turma', 'acessar');
    const isDisabledInclude = id ? !canEditInclude : !canCreateInclude

    useEffect(() => {
        if(searchTerm == undefined || searchTerm == ''){
            setAddStudents(null);
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
          searchStudent(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const searchStudent = useCallback(async (e) => {
        try {
             ApiService.get(`/pessoa`, {params: {search: e, turma_id: id}})
            .then((response) => {
                setAddStudents(response.data)
                // toast('success', 'Aluno adicionado com sucesso');
            })
            .catch((error) => {
                // toast('error', 'Ocorreu um erro');

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getCourse = useCallback(async () => {
        try {
            ApiService.get(`/cursos`)
            .then((response) => {
              setCourses(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const updateStudentList = useCallback(async (data) => {
        setClassStudents([...data]);
        setAddStudents(null);
    }, []);
    
    const getCourseById = useCallback(async () => {
        try {
            ApiService.get(`/turmas/${id}`)
            .then((response) => {
                console.log(response.data);
                const turma = response.data.turma;
                const quantidade_de_aluno = turma.quantidade_de_alunos === 0 ? "" : turma.quantidade_de_alunos;

                setClasses({
                    ...turma,
                    quantidade_de_alunos: quantidade_de_aluno
                });
              setCoursesInClasses(response.data.coursesInClasses);

            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) getCourseById();
        getCourse();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [pathname]);


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        if(id) {
            if (values.Curso_Historico) delete values.Curso_Historico;
            await ApiService.put(`/turmas/${id}`, { values })
            .then((response) => {
                toast('success', 'Turma atualizada com sucesso')
            })
            .catch((error) => {
                toast('error', error.response.data.error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })
        } else {
            await ApiService.post('/turmas', { values })
            .then((response) => {
                toast('success', 'Criado com sucesso')
                resetForm()
            })
            .catch((error) => {
                toast('error', error.response.data.error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })
        }


        setSubmitting(false);
    } 

    

    const handleInputFocus = () => {
        setIsInputFocused(true);
      }
    
    const handleInputBlur = () => {
        setIsInputFocused(false);
    }

    const data = [];
    const alunos = classes?.Curso_Historico?.map(i => ({
        nome_do_aluno: i.User.nome, 
        cpf: i.User.cpf,
        celular: i.User?.celular,
        status: i.status,
        motivo_reprovacao: i.motivo_reprovacao,
        observacao: i.observacao
    })) || [];

    const cleanClasses = {
        ano: classes?.ano,
        nome_do_curso: classes?.Curso?.nome,
        dia_da_semana: classes?.dia_da_semana,
        horario: classes?.horario,
        modo: classes?.modo,
        local: classes?.local,
        grupo: classes?.grupo,
    };


    const addedNames = {}; // objeto para armazenar nomes já adicionados
    
    if (alunos.length === 0) {
        const newRow = {
            ...cleanClasses,
            nome_do_curso: classes?.Curso?.nome,
            nome_do_aluno: "",
            cpf: "",
            celular: "",
            status: "",
            motivo_reprovacao: "",
            observacao: ""
        };
        data.push(newRow);
    }
    
    for (const aluno of alunos) {
        const newRow = {
            ...cleanClasses,
            nome_do_curso: classes?.Curso?.nome,
            nome_do_aluno: aluno.nome_do_aluno,
            cpf: aluno.cpf,
            celular: aluno.celular,
            status: aluno.status,
            motivo_reprovacao: aluno.motivo_reprovacao,
            observacao: aluno.observacao
        };
        if(!addedNames[aluno.nome_do_aluno]) { // verifica se o nome já foi adicionado antes
            data.push(newRow);
            addedNames[aluno.nome_do_aluno] = true; // marca o nome como adicionado
        }
    }
    const generateOptions = (key) => ({ label: key, key });

    const headers = [
        // para turmas
        generateOptions('ano'),
        generateOptions('nome_do_curso'),
        generateOptions('dia_da_semana'),
        generateOptions('horario'),
        generateOptions('modo'),
        generateOptions('local'),
        generateOptions('grupo'),

        // para alunos
        generateOptions('nome_do_aluno'),
        generateOptions('cpf'),
        generateOptions('celular'),
        generateOptions('status'),
        generateOptions('motivo_reprovacao'),
        generateOptions('observacao')
    ];

    const headerLabels = headers.map(header => header.label);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(data, { header: headerLabels });
        const wb = { Sheets: { 'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const nData = new Blob([excelBuffer], { type: fileType})
        FileSaver.saveAs(nData, 'turmas' + fileExtension)
    }

    const handleChangeSearch = useDebouncedCallback((event) => {
        setSearchTerm(event.target.value);
    });

    
    React.useEffect(() => {
        setSearchTerm(searchTerm);
    }, [searchTerm]);

    React.useEffect(() => {
        return () => handleChangeSearch.cancel();
    });


  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Turmas"}
                subtitle={id ? "Editar Turma" : "Cadastro de Turmas"}
                titleUrl={"/app/listar-turmas"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        {
            id && (
                <Box display='flex' justifyContent='flex-end' mb={1}>
                    <Tooltip
                        title="Exportar dados"
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <CSVStyled onClick={(e) => exportToExcel()}>
                            {/* <CSVStyled data={data} headers={headers} filename={'meu-arquivo.csv'}> */}
                            <Typography
                                variant={"h5"}
                                color="#ffffff"
                                mb={.25}
                            >
                                Download
                            </Typography>
                            <DownloadIcon/>
                           {/*  </CSVStyled> */}
                        </CSVStyled>
                    </Tooltip>
                </Box>
            )

        }
        <Paper sx={{p: '40px'}}>
            {state && (
                // state.backUrl &&
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px', mt: '1rem'}, mb: '2rem'}}
                    onClick={() => navigate(state?.backUrl || '/app/listar-turmas', { state: state?.filters })}
                >
                    Voltar
                </Button>
            )}
            <Formik
                initialValues={classes}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
            >
                {({values, isSubmitting, handleChange, setFieldValue}) => (
                    <Form style={{width: '100%'}} noValidate autoComplete='off'>
                        <Grid container spacing={3} alignContent={'center'}>
                            
                            <Grid item xs={6} >
                                <FormControl fullWidth>
                                    <InputLabel shrink id="curso_id">Curso</InputLabel>
                                    <JumboSelectField
                                        labelId="curso_id"
                                        id="curso_id"
                                        name="curso_id"
                                        value={updateInputValue(classes, values, 'curso_id')}
                                        label="Curso"
                                        disabled={isDisabled}
                                        notched
                                    >
                                        {courses.length >0 && courses.filter((course) => course.tipo_curso !== 'antigo').map((course) => 
                                            <MenuItem value={course.curso_id}>{course.nome}</MenuItem>
                                        )}
                                    </JumboSelectField>
                                    {id && !courses.some(y => y.curso_id === values.curso_id && values.curso_id) && (
                                        <FormHelperText error>
                                            O curso selecionado foi excluído. Por favor, selecione outro curso.
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth>
                                    <InputLabel shrink id="ano">Ano</InputLabel>
                                    <JumboSelectField
                                        labelId="ano"
                                        id="ano"
                                        name="ano"
                                        value={updateInputValue(classes, values, 'ano')}
                                        label="Ano"
                                        disabled={isDisabled}
                                        notched
                                    >
                                        {generateYearOptions()}
                                    </JumboSelectField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} >
                                <FormControl fullWidth>
                                    <InputLabel shrink id="periodicidade">Periodicidade</InputLabel>
                                    <JumboSelectField
                                        labelId="periodicidade"
                                        id="periodicidade"
                                        name="periodicidade"
                                        value={updateInputValue(classes, values, 'periodicidade')}
                                        label="Periodicidade"
                                        disabled={isDisabled}
                                        notched
                                    >
                                        <MenuItem value={'janeiro'}>Janeiro</MenuItem>
                                        <MenuItem value={'fevereiro'}>Fevereiro</MenuItem>
                                        <MenuItem value={'marco'}>Março</MenuItem>
                                        <MenuItem value={'abril'}>Abril</MenuItem>
                                        <MenuItem value={'maio'}>Maio</MenuItem>
                                        <MenuItem value={'junho'}>Junho</MenuItem>
                                        <MenuItem value={'julho'}>Julho</MenuItem>
                                        <MenuItem value={'agosto'}>Agosto</MenuItem>
                                        <MenuItem value={'setembro'}>Setembro</MenuItem>
                                        <MenuItem value={'outubro'}>Outubro</MenuItem>
                                        <MenuItem value={'novembro'}>Novembro</MenuItem>
                                        <MenuItem value={'dezembro'}>Dezembro</MenuItem>
                                        <MenuItem value={'1º Bimestre'}>1º Bimestre</MenuItem>
                                        <MenuItem value={'2º Bimestre'}>2º Bimestre</MenuItem>
                                        <MenuItem value={'3º Bimestre'}>3º Bimestre</MenuItem>
                                        <MenuItem value={'4º Bimestre'}>4º Bimestre</MenuItem>
                                        <MenuItem value={'1º Semestre'}>1º Semestre</MenuItem>
                                        <MenuItem value={'2º Semestre'}>2º Semestre</MenuItem>
                                        <MenuItem value={'1º Ano'}>1º Ano</MenuItem>
                                        <MenuItem value={'2º Ano'}>2º Ano</MenuItem>
                                        <MenuItem value={'3º Ano'}>3º Ano</MenuItem>
                                        <MenuItem value={'4º Ano'}>4º Ano</MenuItem>
                                        <MenuItem value={'Anual'}>Anual</MenuItem>
                                        <MenuItem value={'Eventual'}>Eventual</MenuItem>
                                        <MenuItem value={'Contínuo'}>Contínuo</MenuItem>
                                        <MenuItem value={'Outro'}>Outro</MenuItem>


                                    </JumboSelectField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth>
                                    <InputLabel shrink id="dia_da_semana">Dia da semana</InputLabel>
                                    <JumboSelectField
                                        labelId="dia_da_semana"
                                        id="dia_da_semana"
                                        name="dia_da_semana"
                                        value={updateInputValue(classes, values, 'dia_da_semana')}
                                        label="Dia da semana"
                                        disabled={isDisabled}
                                        notched
                                    >
                                        <MenuItem value={'Domingo'}>Domingo</MenuItem>
                                        <MenuItem value={'Segunda'}>Segunda</MenuItem>
                                        <MenuItem value={'Terça'}>Terça</MenuItem>
                                        <MenuItem value={'Quarta'}>Quarta</MenuItem>
                                        <MenuItem value={'Quinta'}>Quinta</MenuItem>
                                        <MenuItem value={'Sexta'}>Sexta</MenuItem>
                                        <MenuItem value={'Sábado'}>Sábado</MenuItem>
                                    
                                    </JumboSelectField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth>
                                <InputMask
                                    mask="99:99"
                                    value={updateInputValue(classes, values, 'horario')}
                                    onChange={handleChange}
                                    disabled={isDisabled}
                                >
                                    {() => 
                                        <JumboTextField
                                            fullWidth
                                            name="horario"
                                            label="Horário"
                                            disabled={isDisabled}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    }
                                </InputMask>
                                
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth>
                                    <InputLabel shrink id="modo">Modo</InputLabel>
                                    <JumboSelectField
                                        labelId="modo"
                                        id="modo"
                                        name="modo"
                                        value={updateInputValue(classes, values, 'modo')}
                                        label="Modo"
                                        disabled={isDisabled}
                                        notched
                                    >
                                        <MenuItem value={'EAD'}>On-line</MenuItem>
                                        <MenuItem value={'Presencial'}>Presencial</MenuItem>
                                        <MenuItem value={'Hibrido'}>Híbrido</MenuItem>
                                        <MenuItem value={'Outros'}>Outros</MenuItem>
                                    
                                    </JumboSelectField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth>
                                    <InputLabel shrink id="local">Local</InputLabel>
                                    <JumboSelectField
                                        labelId="local"
                                        id="local"
                                        name="local"
                                        value={updateInputValue(classes, values, 'local')}
                                        label="Local"
                                        disabled={isDisabled}
                                        notched
                                    >
                                        <MenuItem value={'Casa Transitória'}>Casa Transitória</MenuItem>
                                        <MenuItem value={'Sede'}>Sede Maria Paula</MenuItem>
                                        <MenuItem value={'sede_santo_amaro'}>Sede Santo Amaro</MenuItem>
                                        <MenuItem value={'Outro'}>Outro</MenuItem>
                                    </JumboSelectField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth>
                                    <InputLabel shrink id="grupo">Grupo</InputLabel>
                                    <JumboSelectField
                                        labelId="grupo"
                                        id="grupo"
                                        name="grupo"
                                        value={updateInputValue(classes, values, 'grupo')}
                                        label="Grupo"
                                        disabled={isDisabled}
                                        notched
                                    >
                                        <MenuItem value={null}>Sem seleção</MenuItem>
                                        <MenuItem value={'01'}>01</MenuItem>
                                        <MenuItem value={'02'}>02</MenuItem>
                                        <MenuItem value={'03'}>03</MenuItem>
                                        <MenuItem value={'04'}>04</MenuItem>
                                        <MenuItem value={'05'}>05</MenuItem>
                                        <MenuItem value={'06'}>06</MenuItem>
                                        <MenuItem value={'88'}>88</MenuItem>
                                        <MenuItem value={'99'}>99</MenuItem>
                                    </JumboSelectField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <JumboTextField
                                    fullWidth
                                    name="quantidade_de_alunos"
                                    label="Quantidade de alunos"
                                    value={updateInputValue(classes, values, 'quantidade_de_alunos')}
                                    onChange={(e) => setFieldValue('quantidade_de_alunos', e.target.value.replace(/\s+/g, ''))}
                                    sx={{ background: '#ffffff'}}
                                    disabled={isDisabled}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            

                            {!id ? (
                                <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                    <LoadingButton
                                        color="success"
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                        loading={isSubmitting}
                                        disabled={!hasPermission('Turmas', 'criar')}
                                    >
                                        Salvar
                                    </LoadingButton>
                                </Grid>
                            ):(
                                <>
                                    <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                    <LoadingButton
                                        color="success"
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                        loading={isSubmitting}
                                        disabled={isDisabled || isInputFocused}
                                    >
                                        Atualizar
                                    </LoadingButton>
                                </Grid>
                                    <Grid item xs={12}>
                                       {/*  {
                                            isDisabledInclude || !canView
                                            ?
                                            undefined
                                            :
                                            <>
                                                <Grid item xs={4} sx={{textAlign: 'center'}}> 
                                                    <LinkStyle
                                                        to={`/app/inserir-aluno-turma/${id}`}
                                                        state={{ backUrl: location.pathname }}
                                                        style={{color: '#ffff', textDecoration: 'none', fontSize: '1.1rem'}}
                                                    >
                                                       Clique para adicionar aluno na turma
                                                    </LinkStyle>
                                                </Grid>
                                            </>
                                        } */}

                                        {
                                            (addStudents != null && addStudents.length > 0) && <ListRow data={addStudents} updateStudentList={(data) => updateStudentList(data)} classStudents={classStudents} setSearchTerm={setSearchTerm}/>
                                        }
                                        {
                                            (addStudents != null && addStudents.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                                        }

                                        <Typography variant={"h4"} mb={1} sx={{ marginTop: '2rem', display: 'none' }}>Lista de alunos matriculados</Typography>

                                        {/* { (classStudents.length > 0 && classStudents[0].pessoa_id == null)
                                            ? <Typography>Sem resultados correspondentes</Typography>
                                            :  */}
                                           {/*  <ClassStudentsList 
                                                setClassStudents={setClassStudents} classStudents={classStudents} 
                                                courses={courses}
                                                handleInputFocus={handleInputFocus}
                                                handleInputBlur={handleInputBlur}
                                                coursesInClasses={coursesInClasses}
                                                classes={classes}
                                            /> */}
                                            {/* } */}

                                    </Grid>
                                </>

                            )}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default ClassForm