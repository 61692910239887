import React, { useState, useCallback, useEffect, Fragment, useContext } from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Grid, Paper, Stack, useMediaQuery, Typography, Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ApiService } from "app/servicesTwo/ApiService";
import ProfileItem from "./ProfileItem";
import JumboSearch from "@jumbo/components/JumboSearch";
import { Link, useLocation } from "react-router-dom";
import { PermissionContext } from "app/contexts/PermissionContext";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboSearchWithoutDebounce from "@jumbo/components/JumboSearchWithoutDebounce";

const ProfileList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const [profiles, setProfiles] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const { hasPermission } = useContext(PermissionContext);

  const getProfiles = useCallback(async () => {
    try {
      ApiService.get("/perfil")
        .then((response) => {
          setProfiles(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const searchProfile = useCallback(async (e) => {
    try {
      ApiService.get("/perfil", { params: { search: e } })
        .then((response) => {
          setProfiles(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getProfiles();
  }, []);

  useEffect(() => {
    Swal.close();
  }, [location.pathname, Swal]);

  useEffect(() => {
    console.log("atualizou profiles", profiles);
  }, [setProfiles, profiles]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault();

        setTimeout(() => {
          searchProfile(searchTerm);
        }, 1000)
    }
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={"Perfis"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "40px" }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Link to="/app/novo-perfil" style={{ textDecoration: "none", pointerEvents: !hasPermission('Perfis', 'criar') ? 'none' : '' }} state={{ backUrl: location.pathname }}>
            <LoadingButton
              color="success"
              type="submit"
              variant="contained"
              size="large"
              disabled={!hasPermission('Perfis', 'criar')}
              sx={{
                maxWidth: { md: "200px", mt: "1rem" },
                marginBottom: "2rem",
              }}
            >
              Novo Perfil
            </LoadingButton>
          </Link>
        </Grid>
        <Typography 
            variant="h6" 
            sx={{fontWeight: '500', marginBottom: '1rem', marginLeft: '1rem'}}
        >
            Busque por nome do perfil.
        </Typography>

        <Box
            display='flex'
            alignItems='center'
            gap='1rem'
            marginBottom='1rem'
            flexWrap='wrap'
            onKeyDown={(e) => {
                handleKeyDown(e, searchTerm)
            }}
        >
          <Box display='flex' alignItems='center'>
              <JumboSearchWithoutDebounce
                  defaultValue={searchTerm}
                  onChange={(e) => setSearchTerm(e)}
                  placeholder='Digite o nome do perfil aqui...'
                  sx={{
                      width: '440px',
                  }}
              /> 
          </Box>

          <Box display='flex' alignItems='center' gap='1rem'>
              <Button variant={'contained'} onClick={() => searchProfile(searchTerm)}>
                  Filtrar
              </Button>
          </Box>
        </Box>

        <Fragment>
          {profiles &&
            profiles.map((user, key) => (
              <ProfileItem
                item={user}
                setProfiles={(data) => setProfiles([...data])}
                profiles={profiles}
                key={key}
              />
            ))}
          {profiles && profiles.length == 0 && (
            <Typography>Sem resultados correspondentes</Typography>
          )}
        </Fragment>
      </Paper>
    </JumboContentLayout>
  );
};

export default ProfileList;
