import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  Typography,
  Box,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  Zoom,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { ApiService } from 'app/servicesTwo/ApiService';
import EvaluateDocumentsItem from './EvaluateDocumentsItem';
import JumboSearch from '@jumbo/components/JumboSearch';
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVStyled } from '../coursesIntro/CoursesIntroForm';
import JumboSearchWithoutDebounce from "@jumbo/components/JumboSearchWithoutDebounce";

const EvaluateDocumentsList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const Swal = useSwalWrapper();

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const [classes, setClasses] = useState(null);
  const [searchTerm, setSearchTerm] = useState({
    class: '',
    user: '',
  });
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(null);
  const [yearSelected, setYearSelected] = useState('');
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [docsSelected, setDocsSelected] = useState('todos');

  const { hasPermission } = useContext(PermissionContext);

  const getClasses = useCallback(async (params, toastInfo = undefined) => {
    // console.log('************** EXECUTOU GET CLASSES **************')
    // console.log('params', params);
    // console.log(toastInfo);
    try {
      const { page, perPage, yearSelected, searchTerm, docsSelected } = params;
      ApiService.get('/aguardando-avaliacao-documentos', {
        params: { page, perPage, yearSelected, searchTerm, docsSelected },
      })
        .then((response) => {
          // console.log(response.data);
          setClasses(response.data.allUsers);
          setCount(response.data.count);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, yearSelected, docsSelected]);

  const getClassesToExcel = useCallback(async (params, toastInfo = undefined) => {
    try {
      setLoadingExcel(true);
      const { yearSelected, searchTerm, docsSelected } = params;
      // console.log('Params enviados para a API:', { yearSelected, searchTerm, docsSelected });

      const response = await ApiService.get('/aguardando-avaliacao-documentos-excel', {
        params: { yearSelected, searchTerm, docsSelected },
      });

      console.log('Dados recebidos da API:', response.data?.allUsers);

      const data = [];
      if (response.data?.allUsers?.length > 0) {
        for (const item of response.data?.allUsers) {
          console.log('Item processado:', item);
          const nomeCurso = item?.Turma.Curso?.nome;
          const cleanClasses = {
            nome_do_curso_escolhido: nomeCurso,
            nome_do_inscrito: item?.User.nome,
            cpf: item?.User.cpf,
            telefone: item?.User.telefone,
            celular: item?.User.celular,
            email: item?.User?.email,
            horario_escolhido: item?.Turma?.horario,
            dia_da_semana_escolhido: item?.Turma?.dia_da_semana,
            local: item?.Turma?.local,
            atende_os_requisitos: item?.User?.atende_requisitos ? 'SIM' : 'NAO'
          };
          data.push(cleanClasses);
        }
      }

      console.log('Dados processados para o Excel:', data);

      const generateOptions = (key) => ({ label: key, key });

      const headers = [
        generateOptions('nome_do_curso_escolhido'),
        generateOptions('nome_do_inscrito'),
        generateOptions('cpf'),
        generateOptions('telefone'),
        generateOptions('celular'),
        generateOptions('email'),
        generateOptions('horario_escolhido'),
        generateOptions('dia_da_semana_escolhido'),
        generateOptions('local'),
        generateOptions('atende_os_requisitos'),
      ];

      const headerLabels = headers.map((header) => header.label);

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(data, { header: headerLabels });
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const nData = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(nData, 'inscritos_curso' + fileExtension);
    } catch (err) {
      console.error('Erro ao gerar Excel:', err);
    }

    setLoadingExcel(false);
  }, []);

  const handleFilter = useCallback(async (toastInfo, resetPage) => {
  // const handleFilter = (toastInfo, resetPage) => {
    console.log('Ano selecionado:', yearSelected);
    console.log('Docs selecionado:', docsSelected);
    
    if(resetPage){
      setPage(1);
    }
  
    const searchParams = {
      docsSelected,
      yearSelected,
      searchTerm,
      page: resetPage ? 1 : page,
      perPage,
    };
    console.log('searchParams:', searchParams);

    getClasses(searchParams, toastInfo);
  }, [docsSelected, page, yearSelected, searchTerm, perPage]);

  // };

  useEffect(() => {
    const searchParams = {
      docsSelected,
      yearSelected,
      searchTerm,
      page,
      perPage,
    };

    getClasses(searchParams);
  }, [page, count, perPage, getClasses]);

  useEffect(() => {
    Swal.close();
  }, [location.pathname]);

  const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
          e.preventDefault();

          handleFilter(null, true)
      }
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={'Listagem de Pessoas Aguardando Aprovação'} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        <Box display='flex' justifyContent='flex-end' mb={1}>
          <Tooltip title='Exportar dados' TransitionComponent={Zoom} arrow>
          <CSVStyled
            sx={{
              pointerEvents: loadingExcel ? 'none' : undefined,
              opacity: loadingExcel ? 0.7 : 1,
            }}
            onClick={async (e) => {
              if (loadingExcel) return; // Evita múltiplos cliques enquanto está carregando
              const params = { yearSelected, searchTerm, docsSelected };
              getClassesToExcel(params);
            }}
          >
            {loadingExcel ? (
              <>
                <CircularProgress size={20} sx={{ color: '#ffffff', marginRight: 1 }} />
                <Typography variant="h5" color="#ffffff" mb={0.25}>
                  Exportando...
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h5" color="#ffffff" mb={0.25}>
                  Download
                </Typography>
                <DownloadIcon />
              </>
            )}
          </CSVStyled>
          </Tooltip>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          gap='1rem'
          marginBottom='1rem'
          flexWrap='wrap'
          onKeyDown={(e) => {
            handleKeyDown(e)
          }}
        >
          <Box display='flex' alignItems='center' gap='1rem'>
            <FormControl fullWidth size='small' sx={{ width: 100 }}>
              <InputLabel shrink id='Selecione ano'>
                Selecione ano
              </InputLabel>
              <Select
                labelId='Selecione ano'
                id='ano'
                name='ano'
                label='Selecione ano'
                notched
                value={yearSelected}
                onChange={(e) => setYearSelected(e.target.value)}
              >
                <MenuItem value={''}>Selecione</MenuItem>
                <MenuItem value={new Date().getFullYear() - 2}>
                  {new Date().getFullYear() - 2}
                </MenuItem>
                <MenuItem value={new Date().getFullYear() - 1}>
                  {new Date().getFullYear() - 1}
                </MenuItem>
                <MenuItem value={new Date().getFullYear()}>
                  {new Date().getFullYear()}
                </MenuItem>
                <MenuItem value={new Date().getFullYear() + 1}>
                  {new Date().getFullYear() + 1}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <JumboSearchWithoutDebounce
            onChange={(e) => setSearchTerm((prev) => ({ ...prev, class: e }))}
            placeholder='Digite o nome do curso aqui...'
            sx={{
              width: '280px',
            }}
          />

          <JumboSearchWithoutDebounce
            onChange={(e) => setSearchTerm((prev) => ({ ...prev, user: e }))}
            placeholder='Digite o nome do aluno aqui...'
            sx={{
              width: '280px',
            }}
          />

          <Box display='flex' alignItems='center' gap='1rem'>
            <FormControl fullWidth size='small'>
              <InputLabel shrink id='Documentação'>
                Documentação
              </InputLabel>
              <Select
                labelId='Documentação'
                id='docs'
                name='docs'
                label='Documentação'
                notched
                value={docsSelected}
                onChange={(e) => setDocsSelected(e.target.value)}
              >
                <MenuItem value={'todos'}>
                  Listar todos
                </MenuItem>
                <MenuItem value={'com_docs'}>
                  Listar com documentação
                </MenuItem>
                <MenuItem value={'sem_docs'}>
                  Listar sem documentação
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Button size='small' variant={'contained'} onClick={() => handleFilter(null, true)}>
            Filtrar
          </Button>
        </Box>
        <Fragment>
          {classes &&
            classes.map((user, key) => (
              <EvaluateDocumentsItem
                item={user}
                setClasses={(data) => setClasses([...data])}
                classes={classes}
                key={key}
                getClasses={getClasses}
                page={page}
                perPage={perPage}
                handleFilter={handleFilter}
                yearSelected={yearSelected}
                searchTerm={searchTerm}
              />
            ))}
          {classes && classes.length == 0 && (
            <Typography>Sem resultados correspondentes</Typography>
          )}
          <Box sx={{ mt: 2 }}>
            <Pagination
              color='primary'
              count={count}
              page={page}
              onChange={(event, value) => setPage(value)}
            />
          </Box>
        </Fragment>
      </Paper>
    </JumboContentLayout>
  );
};

export default EvaluateDocumentsList;
