import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tooltip,
  TextField,
  Autocomplete,
} from "@mui/material";

const MessageSelector = ({
  courseSelected,
  messages,
  messageSelected,
  handleMessageChange,
  marginTop = undefined,
}) => {
  if (!courseSelected) return null;

  const labels = {
    inscricao_no_curso: 'Inscrição no Curso',
  /*   aprovado: "Aprovado",
    reprovado: "Reprovado", */
    documentacao_invalida: "Documentação Inválida",
  };

  const categories = Object.keys(labels);

  return (
    <>
      {categories.map((tipo) => {
        const messagesFiltered = messages.filter(
          (msg) => msg.tipo_mensagem === tipo.toLowerCase()
        );

        return (
          <Grid item xs={12} md={4} key={tipo} mt={marginTop}>
            <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" mb={2} fontWeight="bold">
                  {labels[tipo]}
                </Typography>
                <Autocomplete
                  options={messagesFiltered}
                  /* groupBy={(option) => labels[option.tipo_mensagem]} */
                  getOptionLabel={(option) => option.assunto}
                  value={
                    messagesFiltered.find(
                      (msg) => msg.mensagem_id === messageSelected[tipo].id
                    ) || null
                  }
                  onChange={(_, newValue) =>
                    handleMessageChange(tipo, newValue)
                  }
                  renderInput={(params) => <TextField {...params} label="Selecione uma mensagem" />}
                  renderOption={(props, option) => (
                    <li {...props} key={option.mensagem_id}>
                      <Tooltip title={option.descricao} arrow>
                        <Typography variant="body2">{option.assunto}</Typography>
                      </Tooltip>
                    </li>
                  )}
                  noOptionsText={'Sem opções'}
                />
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

export default MessageSelector;
