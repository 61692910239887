import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { ApiService } from 'app/servicesTwo/ApiService';
import CoursesIntroItem from './CoursesIntroItem';
import JumboSearch from '@jumbo/components/JumboSearch';
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboSearchWithoutDebounce from "@jumbo/components/JumboSearchWithoutDebounce";

const CoursesIntroList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const Swal = useSwalWrapper();

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const [classes, setClasses] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [yearSelected, setYearSelected] = useState('');
  const [classesOpen, setClassesOpen] = useState('');

  const { hasPermission } = useContext(PermissionContext);

  const getClasses = useCallback(async () => {
    try {
      ApiService.get('/cursos-introdutorios')
        .then((response) => {
          console.log(response.data);
          setClasses(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const searchClass = useCallback(async (e) => {
    console.log('pesquisar', e);
    try {
      ApiService.get('/cursos-introdutorios', { params: { searchTerm: e } })
        .then((response) => {
          setClasses(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleFilter = () => {
    // Aqui você pode adicionar a lógica de filtro conforme necessário
    console.log('Ano selecionado:', yearSelected);
    const searchParams = {
      yearSelected,
      searchTerm,
      classesOpen
    }
    searchClass(searchParams)
  };

  useEffect(() => {
    getClasses();
  }, []);

  useEffect(() => {
    console.log('atualizou', classes);
  }, [setClasses, classes]);

  useEffect(() => {
    Swal.close();
  }, [location.pathname, Swal]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault();

        setTimeout(() => {
          handleFilter();
        }, 1000)
    }
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={'Setup de Curso no Ano'} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Link
            to='/app/novo-abrir-inscricoes'
            style={{
              textDecoration: 'none',
              pointerEvents: !hasPermission('Setup de Curso no Ano', 'criar')
                ? 'none'
                : '',
            }}
            state={{ backUrl: location.pathname }}
          >
            <LoadingButton
              color='success'
              type='submit'
              variant='contained'
              size='large'
              disabled={!hasPermission('Setup de Curso no Ano', 'criar')}
              sx={{
                maxWidth: { md: '300px', mt: '1rem' },
                marginBottom: '2rem',
              }}
            >
              Setup de Novo Curso
            </LoadingButton>
          </Link>
        </Grid>

        <Box
          display='flex'
          alignItems='center'
          gap='1rem'
          marginBottom='1rem'
          flexWrap='wrap'
          onKeyDown={(e) => {
            handleKeyDown(e, searchTerm)
          }}
        >
          <Box display='flex' alignItems='center' gap='1rem'>
            <FormControl fullWidth size='small' sx={{width: 100}}>
              <InputLabel shrink id='Selecione ano'>
                Selecione ano
              </InputLabel>
              <Select
                labelId='Selecione ano'
                id='ano'
                name='ano'
                label='Selecione ano'
                notched
                value={yearSelected}
                onChange={(e) => setYearSelected(e.target.value)}
              >
                <MenuItem value={''}>
                  Selecione
                </MenuItem>
                <MenuItem value={new Date().getFullYear()}>
                  {new Date().getFullYear()}
                </MenuItem>
                <MenuItem value={new Date().getFullYear() + 1}>
                  {new Date().getFullYear() + 1}
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth size='small' sx={{width: 150}}>
              <InputLabel shrink id='filter-classes-status'>
                Status da turma
              </InputLabel>
              <Select
                labelId='filter-classes-status'
                id='aberto'
                name='aberto'
                label='Status da turma'
                notched
                value={classesOpen}
                onChange={(e) => setClassesOpen(e.target.value)}
              >
                <MenuItem value={''}>
                  Selecione
                </MenuItem>
                <MenuItem value={'sim'}>
                 Aberta
                </MenuItem>
                <MenuItem value={'nao'}>
                  Fechada
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              size='small'
              variant={'contained'}
              onClick={handleFilter}
            >
              Filtrar
            </Button>
          </Box>
          <JumboSearchWithoutDebounce
              defaultValue={searchTerm}
              onChange={(e) => setSearchTerm(e)}
              placeholder='Digite o nome do curso aqui...'
              sx={{
                  width: '440px',
              }}
          />
        </Box>

        <Fragment>
          {classes &&
            classes.map((user, key) => (
              <CoursesIntroItem
                item={user}
                setClasses={(data) => setClasses([...data])}
                classes={classes}
                key={key}
              />
            ))}
          {classes && classes.length == 0 && (
            <Typography>Sem resultados correspondentes</Typography>
          )}
        </Fragment>
      </Paper>
    </JumboContentLayout>
  );
};

export default CoursesIntroList;
