import React, { useState, useCallback, useEffect } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';

import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  TableRow,
  TableCell,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useLocation } from 'react-router-dom';
import CourseApprovalList from 'app/components/CourseApprovalList/CourseApprovalList';
import ContinuingCoursesList from 'app/components/ContinuingCoursesList/ContinuingCoursesList';
import CurrentCoursesAccordion from 'app/components/CurrentCoursesAccordion/CurrentCoursesAccordion';
import { CourseWithAllStatusList } from 'app/components/CourseWithAllStatusList/CourseWithAllStatusList';
import CompatibleCoursesList from 'app/components/CompatibleCoursesList/CompatibleCoursesList';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { GreatVibesRegular } from '../../../../assets/fonts/GreatVibes-regular';
import { capitalizeFLetter } from '@jumbo/utils';
import FileListSlim from "app/components/FileUploadSlim/FileListSlim";
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ModalAddDocs = ({ user, username, userId = null, handleClose, curso_introdutorio_historico_id }) => {
  const { theme } = useJumboTheme();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  window.pdfMake.vfs['GreatVibes-Regular.ttf'] = GreatVibesRegular;
  pdfMake.fonts = {
    Roboto: {
      normal:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
    GreatVibes: {
      normal: 'GreatVibes-Regular.ttf',
      bold: 'GreatVibes-Regular.ttf', // Se não houver versão 'bold', você pode usar a mesma
      italics: 'GreatVibes-Regular.ttf',
      bolditalics: 'GreatVibes-Regular.ttf',
    },
  };

  console.log(pdfMake);

  console.log(user);

  const FileListItem = styled.li`
    list-style: none;
    /* margin: 1rem 0; */
    background-color: rgba(0,0,0,.07);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.9rem;

    .inputFile {
      border-radius: 5px;
      border: none;
      outline: none;
      padding: 0.5rem 0.9rem;
    }
  `;

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const { id } = useParams();
  const location = useLocation();

  const [courseHistory, setCourseHistory] = useState([]);
  const [courseHistoryOnlyApproval, setCourseHistoryOnlyApproval] = useState(
    []
  );
  const [courseHistoryFeespOnlyApproval, setCourseHistoryFeespOnlyApproval] =
    useState([]);
  const [courseHistoryOnlyActive, setCourseHistoryOnlyActive] = useState([]);
  const [coursesCompatible, setCoursesCompatible] = useState([]);
  const [coursesContinued, setCoursesContinued] = useState([]);
  const [modalTitle, setModalTitle] = useState('Histórico de Cursos');
  const [modalSubtitle, setModalSubtitle] = useState();
  const [activeComponent, setActiveComponent] = useState('initial');
  const [director, setDirector] = useState([]);

  const coursesCompatibleFiltered = coursesCompatible.filter(
    (item) =>
      item.curso_compativel_id !== null || item?.Turma?.local === 'Externo'
  );

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const [textFile, setTextFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [spiritualCenterSelected, setSpiritualCenterSelected] = useState('');
  const [spiritualCenter, setSpiritualCenter] = useState([]);
  const [userCoursesFromOtherEntities, setUserCoursesFromOtherEntities] =
  useState({
    ano_de_conclusao: '',
    curso_compativel_id: '',
    observacao: '',
    centro_espirita_id: '',
    analise: '',
    selected: [],
  });
  const [entitiesWithDocs, setEntitiesWithDocs] = useState([]);
  // console.log('entitiesWithDocs', entitiesWithDocs)
  // const docs = centro.Documento_De_Curso_Outra_Entidade;

  const Swal = useSwalWrapper();

  const toast = (variant, message) => {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      title: message,
      icon: variant,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      showCloseButton: true,
    });
  };

  const uploadHandler = (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setFiles((prev) => [...prev, file]);
    setFilesSelected((prev) => [...prev, file]);
  
    uploadFile(file);
  };

  const removeFile = (item) => {
    // setFiles(files.filter((file, i) => i !== index));
    console.log('item', item)
  };

  const handleSaveClick = (index) => {
    // setIsEditingFile(false);
    // files.map((file, i) =>
    //   i == index ? (file.nome_label = textFile) : file.nome_label
    // );
    // setTextFile('');
  };

  const getSpiritualCenter = useCallback(async () => {
    try {
      ApiService.get(`/centro-espirita`)
        .then((response) => {
          setSpiritualCenter(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleEditClick = (index) => {
    setIsEditingFile({ active: true, index: index });
    setTextFile(
      files
        .filter(
          (file, i) =>
            i === index &&
            (file.nome_label || file.nome || file instanceof File)
        )
        .map((file) => {
          if (file instanceof File && file.nome_label) {
            return file.nome_label;
          }
          return file.nome_label || file.nome || file.name;
        })
    );
  };

  const handleInputChangeFile = (e) => {
    setTextFile(e.target.value);
  };

  const modalAlertDeleteFile = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeFileFromBd(id);
      }
    });
  };

  const uploadFile = async (file) => {
    console.log('uploadFile');
    console.log('file', file);
  
    let formData = new FormData();
    const randomNumber = Math.floor(Math.random() * 100000);
  
    // Adiciona os dados ao FormData
    formData.append('docs', file);
    formData.append(`nome_label_${randomNumber}`, file.name);
  
    // Adiciona outros dados ao FormData
    formData.append('pessoa_id', userId);
    formData.append('centro_espirita_id', spiritualCenterSelected);
    formData.append('create_course_history', true);
  
    try {
      // Faz a requisição para o backend
      await ApiService.post(`/documentos/upload`, formData);

      setTextFile(null);
      getDocsOutrasEntidades();
      toast('success', 'Adicionado com sucesso');
    } catch (error) {
      if (error.response) {
        console.error(error.response.data, error.response.status);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error('Error', error.message);
      }
    }
  };

  const removeFileFromBd = async (id) => {
    console.log('removeFileFromBd', id)
    ApiService.delete(`/documentos/remove/${id}`)
      .then(() => {
        // setFiles([]);
        // setFilesSelected([]);
        // setTextFile(null);
        getDocsOutrasEntidades();
        toast('success', 'Removido com sucesso');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  const updateLabelFromBd = async (id) => {
    let formData = new FormData();
    formData.append('nome_label', textFile);

    ApiService.post(`/documentos/atualizar/${id}`, formData)
      .then(() => {
        setTextFile(null);
        setIsEditingFile(false)
        getDocsOutrasEntidades();
        toast('success', 'Atualizado com sucesso');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  const currentPath = location.pathname;
  const inClassesURL =
    currentPath.startsWith('/app/editar-turma') ||
    currentPath.startsWith('/app/listar-cursos-outras-entidades') ||
    currentPath.startsWith('/app/listar-curso-lote') ||
    currentPath.startsWith('/app/listar-consultar-historico') ||
    currentPath.startsWith('/app/listar-avaliacao-de-documentos');

  const idSelected = inClassesURL ? userId : id;

  const getDocsOutrasEntidades = useCallback(async () => {
    console.log('chamou getDocsOutrasEntidades')
    try {
      ApiService.get(`/documentos/${userId}`)
        .then((response) => {
          console.log('getDocsOutrasEntidades', response.data);
          setEntitiesWithDocs(response.data)
          // setCourseHistory(response.data.historico_cursos);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourseHistoryFromSpiritualCenter = useCallback(async () => {
    try {
      ApiService.get(`/centro-espirita/historico-usuario/${idSelected}`)
        .then((response) => {
          console.log('cursos centro espirita compativeis', response.data);
          setCoursesCompatible(response.data.cursoHistoricoDoUsuario);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleBackClick = () => {
    setActiveComponent('initial');
    setModalTitle('Histórico de Cursos');
    setModalSubtitle('');
  };

  const handleCompatibleCoursesClick = () => {
    setActiveComponent('compatible');
    setModalTitle('Histórico de Cursos Compatibilizados');
    setModalSubtitle('');
  };

  const handleDetailedHistoryClick = () => {
    setActiveComponent('detailedHistory');
    setModalTitle('Histórico Detalhado de Cursos FEESP');
    setModalSubtitle(
      'Somente constam dados de cursos realizados a partir de 2024'
    );
  };

  const renderHistoryButton = () => {
    return (
      <>
        {activeComponent === 'initial' && (
          <>
            <Button
              size='small'
              variant='contained'
              color='primary'
              style={{ marginRight: 10 }}
              onClick={handleCompatibleCoursesClick}
            >
              Cursos Compatibilizados
            </Button>
            <Button
              size='small'
              variant='contained'
              color='primary'
              onClick={handleDetailedHistoryClick}
            >
              Histórico Detalhado
            </Button>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    getSpiritualCenter();
    getDocsOutrasEntidades();
  }, []);

  const loadImageBase64 = (path) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', path);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  const generatePDF = (historico, pessoa, diretor) => {
    Promise.all([
      loadImageBase64(
        `${process.env.REACT_APP_API_KEY}/images/${diretor?.assinatura}`
      ), // Assinatura
      loadImageBase64('/images/logositefeesp.png'), // Logo
      loadImageBase64('/images/ICONE_AREA_ENSINO.png')
    ]).then(([assinaturaBase64, logoBase64, iconArea]) => {
      const cursos = historico.map((item) => [
        { text: item.ano_de_conclusao ?? '-', alignment: 'center' },
        item.Turma.Curso.nome,
        { text: item.Turma?.Curso?.carga_horaria ?? '-', alignment: 'center' },
      ]);

      const docDefinition = {
        content: [
          {
            columns: [
              {
                image: logoBase64,
                width: 200,
                alignment: 'left',
                margin: [0, 0, 0, 0],
              },
              /*  {
                 text: 'ÁREA DE ENSINO',
                 alignment: 'right',
                 bold: true,
                 fontSize: 16,
                 margin: [0, 35, 0, 0],
               }, */
              {
                image: iconArea,
                width: 135,
                margin: [180, 25, 0, 0],
              },
            ],
          },
          /* {
            text:
              'São Paulo, ' +
              new Date().toLocaleDateString('pt-BR') +
              '\n(Emitido via sistema às ' +
              new Date().toLocaleTimeString('pt-BR') +
              ')',
            style: 'dataEmissao',
            alignment: 'center',
          }, */
          {
            text: '\n\nDECLARAÇÃO DE CURSOS REALIZADOS\n\n',
            style: 'header',
            alignment: 'center',
          },
          {
            text: [
              'Declaramos, para os devidos fins, que ',
              { text: pessoa.nome, bold: true, fontSize: 14, },
              `, CPF ${pessoa.cpf ?? '-'}, concluiu os seguintes cursos em nossa instituição:`
            ],
            style: 'body',
          },
          {
            table: {
              widths: ['auto', '*', 'auto'],
              body: [
                [
                  { text: 'ANO DE CONCLUSÃO', style: 'tableHeader' },
                  { text: 'CURSO', style: 'tableHeader' },
                  { text: 'CARGA HORÁRIA', style: 'tableHeader' },
                ],
                ...cursos,
              ],
            },
            style: 'table',
          },
          {
            text: '\nColocamo-nos à disposição para quaisquer esclarecimentos.',
            style: 'body',
          },
          {
            text: '\n\nAtenciosamente,\n\n',
            style: 'body',
          },
          {
            alignment: 'center',
            stack: [
              {
                image: assinaturaBase64, // Imagem base64 carregada dinamicamente
                width: 150, // Largura da imagem da assinatura
              },
              { text: '______________________________' },
              { text: `${diretor?.User?.nome}`, bold: true },
              { text: 'Diretor da Área de Ensino' },
              {
                text: `Emitido em ${new Date().toLocaleDateString('pt-BR')}, às ${new Date().toLocaleTimeString('pt-BR')}, via sistema. Dúvidas: entrar em contato com a área de Ensino pelo telefone 011 3188-8383 ramal 237`,
                fontSize: 10,
                marginTop: 10,

              }
            ],
          },
        ],
        styles: {
          header: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 10],
          },
          subheader: {
            fontSize: 12,
            margin: [0, 10, 0, 10],
          },
          body: {
            fontSize: 11,
            margin: [0, 10, 0, 10],
          },
          table: {
            margin: [0, 20, 0, 20],
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
            color: 'black',
            fillColor: '#eeeeee',
          },
          dataEmissao: {
            fontSize: 10,
            italics: true,
            margin: [0, 40, 0, 0],
          },
        },
      };

      pdfMake.createPdf(docDefinition).open();
    });
  };

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px', height: '550px' }}>
        <Box sx={{ height: '100%', overflowY: 'auto' }}>
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            marginRight='1rem'
            gap={1}
          >
            {activeComponent !== 'initial' && (
              <Button
                size='small'
                variant='contained'
                color='success'
                onClick={handleBackClick}
              >
                Voltar
              </Button>
            )}
            <Button size='small' variant='contained' onClick={handleClose}>
              Fechar
            </Button>
          </Box>
          <Typography
            variant={'h1'}
            style={{ textAlign: 'center', marginBottom: 30 }}
          >
            Upload de documentos faltantes
          </Typography>

          <React.Fragment>
          <Formik
              initialValues={{}}
              enableReinitialize
            >
              {({ isSubmitting }) => (
                <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                  <Grid container spacing={3} alignContent={'center'}>
                    <Grid item xs={12}>
                      <Box display={'flex'} gap={1} alignItems={'center'}>
                        <Typography variant='h4' fontWeight={'300'}>
                          Candidato selecionado:
                        </Typography>

                        <Typography
                          variant='h4'
                          textTransform={'capitalize'}
                          fontWeight={'600'}
                        >
                          {username}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={4} mb={3}>
                      <FormControl fullWidth>
                        <InputLabel shrink id='Entidade de Origem'>
                          Entidade de Origem
                        </InputLabel>
                        <JumboSelectField
                          labelId='Entidade de Origem'
                          id='Entidade de Origem'
                          name='centro_espirita_id'
                          label='Entidade de Origem'
                          // disabled={spiritualCenterSelected ? true : false}
                          value={
                            userCoursesFromOtherEntities.centro_espirita_id ||
                            spiritualCenterSelected
                          }
                          onChange={(e) =>
                            setSpiritualCenterSelected(e.target.value)
                          }
                          sx={{
                            background: '#ffffff',
                          }}
                          notched
                        >
                          <MenuItem value=''>Selecione</MenuItem>
                          {spiritualCenter.map((item) => (
                            <MenuItem
                              key={item.centro_espirita_id}
                              value={item.centro_espirita_id}
                            >
                              {item.nome_fantasia}
                            </MenuItem>
                          ))}
                        </JumboSelectField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <Button
                        variant='contained'
                        component='label'
                        color='info'
                        sx={{
                          height: '53.13px',
                          fontSize: {
                            xs: '0.7rem',
                          },
                          textAlign: 'center',
                        }}
                      >
                        Adicionar Declarações/Comprovantes
                        <input
                          type='file'
                          hidden
                          name='imagem_area'
                          onChange={uploadHandler}
                        />
                      </Button>
                    </Grid>

                    
                    </Grid>
                  </Form>
              )}
            </Formik>

            <Grid container spacing={3}>
              {entitiesWithDocs
              .filter(item => !spiritualCenterSelected || item.centro.centro_espirita_id === spiritualCenterSelected) // Filtra com base no spiritualCenterSelected
              .map((item, index) => (
                <Grid item xs={12} px={2}>
                  <Box key={index} mb={4}>
                    {/* <CustomTableWithFooter key={index} item={item} /> */}
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography
                        variant='h6'
                        fontSize={'1rem'}
                        fontWeight={'bolder'}
                        gutterBottom
                      >
                        Entidade de Origem: {item?.centro?.nome_fantasia}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: '1px solid rgba(224, 224, 224, 1)',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        width: '100%',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
                      }}
                    >
                      <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.875rem' }}>
                        <tbody>
                          <tr
                            key={1}
                            style={{
                              backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa',
                              transition: 'background-color 0.3s',
                            }}
                          >
                            <td
                              style={{
                                padding: '8px',
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                              }}
                            >
                              Arquivo enviado por um usuário interno do sistema
                            </td>
                          </tr>
                          <TableRow sx={{ background: 'rgba(0,0,0,.05)' }}>
                            <TableCell>
                              <Box
                                display={'flex'}
                                flexWrap={'wrap'}
                                gap={3}
                                flexDirection={'column'}
                              >
                                <Typography>Declaração(ões) da Entidade de Origem</Typography>
                                {item?.documentos.map((item, index) => (
                                  <FileListItem className='file-item' key={item.nome}>
                                    {isEditingFile.active && isEditingFile.index === index
                                        ?
                                        <Box display="flex" gap='1rem'>
                                            <input
                                              className="inputFile"
                                              type="text" 
                                              value={textFile}
                                              onChange={(e) => setTextFile(e.target.value)} />
                                            <Button
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={() => updateLabelFromBd(item.documento_de_curso_outra_entidade_id)}
                                            >
                                                Salvar
                                            </Button>
                                        </Box>
                                        :
                                        <p>
                                            <a
                                              href={`${process.env.REACT_APP_API_KEY}/images/${item.nome}`}
                                              target='_blank'
                                              rel='noreferrer'
                                            >
                                              {item?.nome_label || item?.nome.replace(/^\d+_/, '')}
                                            </a>
                                        </p>
                                    }

                                    {/* <p>
                                      <a
                                        href={`${process.env.REACT_APP_API_KEY}/images/${item.nome}`}
                                        target='_blank'
                                        rel='noreferrer'
                                      >
                                        {item?.nome_label || item?.nome.replace(/^\d+_/, '')}
                                      </a>
                                    </p> */}
                                    <Box display='flex' gap='1rem'>
                                        {/* {
                                            isEditingFile.active && isEditingFile.index === index
                                                ?
                                                <Button
                                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    disableElevation
                                                    variant={"contained"}
                                                    size={"small"}
                                                    color={"error"}
                                                    onClick={() => setIsEditingFile(false)}
                                                >
                                                    Cancelar
                                                </Button>
                                                :
                                                <Button
                                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    disableElevation
                                                    variant={"contained"}
                                                    size={"small"}
                                                    color={"primary"}
                                                    onClick={() => handleEditClick(index)}
                                                >
                                                    <EditIcon />
                                                </Button>
                                        } */}

                                        <Button
                                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            disableElevation
                                            variant={"contained"}
                                            size={"small"}
                                            color={"secondary"}
                                            onClick={() => 
                                              modalAlertDeleteFile(
                                                item.documento_de_curso_outra_entidade_id
                                              )
                                              // removeFile(item.documento_de_curso_outra_entidade_id)
                                            }
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </Box>
                                  </FileListItem>
                                ))}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                </Grid>
              ))}
              {/* {files.length > 0 && (
                <Grid item xs={12}>
                  <Button variant='contained' onClick={() => uploadFile()}>
                    Salvar Declaração(ões)/Comprovante(s)

                  </Button>
                </Grid>
              )} */}

            </Grid>
          </React.Fragment>
        </Box>
      </Paper>
    </JumboContentLayout>
  );
};

export default ModalAddDocs;
