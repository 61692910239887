import { Typography } from '@mui/material';
import capitalize from 'app/utils/capitalize';
import MUIDataTable from 'mui-datatables';
import React from 'react';

const columns = [
  {
    name: 'Turma.ano',
    label: 'Ano',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma.Curso.nome',
    label: 'Nome',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        width: '40%',
      }),
      setCellProps: () => ({
        width: '40%',
      }),
    },
  },
  {
    name: 'Turma.dia_da_semana',
    label: 'Dia da semana',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma.horario',
    label: 'Horário',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma',
    label: 'Local',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        const local = value.centro_espirita_id
          ? value?.Centro_Espirita?.nome_fantasia
          : capitalize(
              value.local === 'Sede'
                ? 'Sede Maria Paula'
                : value.local === 'sede_santo_amaro'
                ? 'Sede Santo Amaro'
                : value.local
            );

        return (
          <>
            <Typography variant={'body1'}>{local ?? '-'}</Typography>
          </>
        );
      },
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        const { rowIndex, tableData } = tableMeta || {};
        const row = tableData?.[rowIndex];
        const status = row?.status;
        const anoTurma = row?.Turma?.ano;
        const tipoTurma = row?.Turma?.tipo_turma;
        const localTurma = row?.Turma?.local;

        console.log({ status });
        const statusLabels = {
          Ativo: 'Ativo',
          Aprovado: 'Aprovado',
          Desistente: 'Desistente',
          Excluído: 'Excluído',
          Outro: 'Outro',
          Reprovado: 'Reprovado',
          pendente: 'Pendente',
          analise_reprovada: 'Análise Reprovada',
          Compativel: 'Compatível',
        };

        const getStatusLabel = () => {
          if (
            tipoTurma === 'antigo' &&
            localTurma !== 'Externo' &&
            anoTurma <= 2024 &&
            status === 'Aprovado'
          ) {
            return 'Incluído';
          }
          if (
            tipoTurma === 'antigo' &&
            localTurma === 'Externo' &&
            status === 'Aprovado'
          ) {
            return 'Compatível';
          }
          return statusLabels[status] || 'Status Desconhecido';
        };

        return (
          <>
            <Typography variant={'body1'}>{getStatusLabel()}</Typography>
          </>
        );
      },
    },
  },
  /* {
      name: 'nome_que_autorizou',
      label: 'Autorizado Por',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>{value ?? '-'}</Typography>
          </>
        ),
      },
    }, */
];

const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  search: false,
  download: false,
  print: false,
  sortFilterList: false,
  viewColumns: false,
  filter: false,
  expandableRows: false,
  expandableRowsHeader: false,
  enableNestedDataAccess: '.',
};

const components = {
  ExpandButton: function (props) {
    return <div style={{ width: '24px' }} />;
  },
};
export const CourseWithAllStatusList = ({ courseHistoryOnlyApproval }) => {
  return (
    <>
      {courseHistoryOnlyApproval.length > 0 ? (
        <MUIDataTable
          data={courseHistoryOnlyApproval}
          columns={columns}
          options={options}
          components={components}
        />
      ) : (
        <Typography>Nenhum histórico encontrado</Typography>
      )}
    </>
  );
};
