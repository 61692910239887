import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Grid, Paper, Stack, useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Box, Button} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { ApiService } from 'app/servicesTwo/ApiService';
import UserItem from "./UserRegistrationItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PermissionContext } from 'app/contexts/PermissionContext';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import JumboSearch from "@jumbo/components/JumboSearch";
import ListRowUser from "../../../components/ListRowUser/ListRowUser";
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboSearchWithoutDebounce from "@jumbo/components/JumboSearchWithoutDebounce";

const validationSchema = yup.object({
    email: yup
        .string('Insira seu email')
        .email('Insira um email válido'),
    senha: yup
        .string('Insira sua senha'),
    nome: yup
        .string('Insira seu nome')
        .required('Preenchimento obrigatório'),
    cpf: yup
        .string(),
    rg: yup
        .string()
        .max(11, 'Deve ter no máximo 11 digitos'),
    data_nascimento: yup
        .date()
        .max(new Date(), 'Não é possivel incluir uma data futura')
        .required('Preenchimento obrigatório'),
    celular: yup
        .string()
});

const UserRegistrationList = () => {
    const navigate = useNavigate();
    const Swal = useSwalWrapper();
    const [searchTerm, setSearchTerm] = useState(null)
    const [addStudents, setAddStudents] = useState(null);  
    const {theme} = useJumboTheme();
    const location = useLocation()
    
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const [users, setUsers] = useState(null)
    const { hasPermission } = useContext(PermissionContext);
    
    const searchStudent = useCallback(async (e) => {
        console.log('e', e)
        try {
                ApiService.get(`/pessoa-sem-perfil`, {params: {search: e }})
            .then((response) => {
                setAddStudents(response.data)
                console.log('response.data', response.data)
                // toast('success', 'Aluno adicionado com sucesso');
            })
            .catch((error) => {
                // toast('error', 'Ocorreu um erro');

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })
        } catch (err) {
            console.log(err);
        }
    }, []);

    const getUsers = useCallback(async () => {
        try {
            ApiService.get('/usuarios-registrados')
            .then((response) => {
              setUsers(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        console.log('atualizou')
    }, [setUsers, users]);

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    const redirectToCreate = useCallback(async (data) => {
        console.log('data', data)
        navigate(`/app/editar-usuario/${data.pessoa_id}`, { state: { release: true, backUrl: location.pathname} })
        // setClassStudents([...data]);
        // setAddStudents(null);
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            setTimeout(() => {
                searchStudent(searchTerm);
            }, 1000)
        }
    };

    return (
        <JumboContentLayout
            header={
                <PageHeader
                    title={"Usuários"}
                />
            }
        
            layoutOptions={layoutOptions}
        >
            {
                lg && (
                    <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                    </Stack>
                )
            }
            <Paper sx={{p: '40px'}}>
                <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 2}} gap={3}> 
                    <Link to="/app/novo-usuario" state={{ release: true, backUrl: location.pathname }} style={{ textDecoration:'none', pointerEvents: !hasPermission('Usuários', 'criar') ? 'none' : '' }}>
                        <LoadingButton
                            color="success"
                            type="submit"
                            variant="contained"
                            size="large"
                            disabled={!hasPermission('Usuários', 'criar') ? true : false}
                            sx={{maxWidth: {md: '200px', mt: '1rem'}, marginBottom: '2rem'}}
                        >
                            Novo Usuário
                        </LoadingButton>
                    </Link>
                    {/* <Link to="/app/novo-curso" style={{ textDecoration:'none', pointerEvents: !hasPermission('Usuários', 'criar') ? 'none' : '' }}>
                        <LoadingButton
                            color="success"
                            type="submit"
                            variant="contained"
                            size="large"
                            disabled={!hasPermission('Usuários', 'criar') ? true : false}
                            sx={{maxWidth: {md: '200px', mt: '1rem'}, marginBottom: '2rem', marginLeft: '2rem'}}
                        >
                            Liberar acesso
                        </LoadingButton>
                    </Link> */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant={"h4"}>Liberar acesso</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                textAlign='start'
                                variant="h6" 
                                sx={{marginBottom: '1rem', marginLeft: '1rem'}}
                            >
                                O campo de pesquisa abaixo é projetado exclusivamente para liberar o acesso ao sistema para novos usuários. Não é destinado à pesquisa na lista abaixo.
                            </Typography>
                        {/* <Typography 
                            textAlign='start'
                            variant="h6" 
                            sx={{fontWeight: '500', marginBottom: '1rem', marginLeft: '1rem'}}
                        >
                            Busque por Nome, CPF ou E-mail.
                        </Typography>
                            <Grid item xs={1} sx={{textAlign: 'center'}}> 
                                <JumboSearch
                                    onChange={(e) => setSearchTerm(e)}
                                    placeholder="Digite o nome, email ou cpf aqui..."
                                    sx={{
                                        width: '440px',
                                        marginBottom: '1rem'
                                    }}
                                />
                            </Grid> */}

                            <Box
                                display='flex'
                                alignItems='center'
                                gap='1rem'
                                marginBottom='1rem'
                                flexWrap='wrap'
                                onKeyDown={(e) => {
                                    handleKeyDown(e, searchTerm)
                                }}
                            >
                                <Box display='flex' alignItems='center'>
                                    <JumboSearchWithoutDebounce
                                        defaultValue={searchTerm}
                                        onChange={(e) => setSearchTerm(e)}
                                        placeholder='Digite o nome, email ou cpf aqui...'
                                        sx={{
                                            width: '440px',
                                        }}
                                    /> 
                                </Box>

                                <Box display='flex' alignItems='center' gap='1rem'>
                                    <Button variant={'contained'} onClick={() => searchStudent(searchTerm)}>
                                        Filtrar
                                    </Button>
                                </Box>
                            </Box>

                            {(addStudents != null && addStudents.length > 0) && 
                                <ListRowUser 
                                    data={addStudents} 
                                    redirectToCreate={redirectToCreate}
                                />
                            }
                            
                            {
                                (addStudents != null && addStudents.length == 0) && <Typography textAlign='start'>Sem resultados correspondentes</Typography>
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                
                <React.Fragment>
                    {
                        users && users.map((user, key) => (
                            <UserItem item={user} setUsers={(data) => setUsers([...data])} users={users} key={key}/>
                        ))
                    }
                </React.Fragment>
            </Paper>
        </JumboContentLayout>
    )
}

export default UserRegistrationList