import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TableCell,
  TableRow,
  Button,
  Tooltip,
  Zoom,
  Grid,
  Select,
  MenuItem,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';
import FileListSlim from '../FileUploadSlim/FileListSlim';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import moment from 'moment';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';

const FileListItem = styled.li`
  list-style: none;
  /* margin: 1rem 0; */
  background-color: rgba(0,0,0,.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.9rem;
`;

const RequerimentAnalisesForEdit = ({
  coursesCompatible,
  showButton = false,
  modalAlertDelete = undefined,
  userId = undefined,
  spiritualCenterSelectedId = undefined,
  spiritualCenterName = undefined,
  getCourseHistoryFromSpiritualCenter = undefined,
  analise = undefined,
  setAnalise = undefined,
  spiritualCenterSelectedNewId = undefined,
  turma = undefined,
  cursoIntroId = undefined,
  disabledButton = undefined,
  setDisabledButton = undefined,
}) => {
  const Swal = useSwalWrapper();

  const { pathname } = useLocation();

  const { hasPermission } = useContext(PermissionContext);

  console.log(pathname)

  const toast = (variant, message) => {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      title: message,
      icon: variant,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      showCloseButton: true,
    });
  };

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const [textFile, setTextFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleAction = () => {
    switch (selectedOption) {
      case 'approved':
        pathname === '/app/listar-avaliacao-de-documentos'
          ? modalAlertUserAnalisesAndTransfer(turma)
          : sendAnalise();
        break;

      case 'rejected':
        modalAlertUserReproved(turma)
        break;

      case 'continue':
        sendAnalise()
        break;

      default:
        toast('warning', 'Selecione uma opção válida de status da análise.');
    }
  };
  /*  const [analise, setAnalise] = useState(''); */

  const uploadHandler = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setFiles([...files, file]);
    setFilesSelected([...filesSelected, file]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const handleSaveClick = (index) => {
    setIsEditingFile(false);
    files.map((file, i) =>
      i == index ? (file.nome_label = textFile) : file.nome_label
    );
    setTextFile('');
  };

  const handleEditClick = (index) => {
    setIsEditingFile({ active: true, index: index });
    setTextFile(
      files
        .filter(
          (file, i) =>
            i === index &&
            (file.nome_label || file.nome || file instanceof File)
        )
        .map((file) => {
          if (file instanceof File && file.nome_label) {
            return file.nome_label;
          }
          return file.nome_label || file.nome || file.name;
        })
    );
  };

  const handleInputChangeFile = (e) => {
    setTextFile(e.target.value);
  };

  const uploadFile = async () => {
    let formData = new FormData();
    filesSelected.forEach((file, index) => {
      if (file instanceof File) {
        formData.append('docs', file);
        formData.append(`nome_label_${index}`, file.nome_label);
      }
    });

    if (files.length > 0) {
      formData.append('pessoa_id', userId); // Adiciona o pessoa_id
      formData.append(
        'centro_espirita_id',
        spiritualCenterSelectedId || spiritualCenterSelectedNewId
      );

      ApiService.post(`/documentos/upload`, formData)
        .then(() => {
          setFiles([]);
          setFilesSelected([]);
          setTextFile(null);
          getCourseHistoryFromSpiritualCenter();
          toast('success', 'Adicionado com sucesso');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }
  };

  const modalAlertDeleteFile = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeFileFromBd(id);
      }
    });
  };

  const modalAlertUserAnalisesAndTransfer = (turmaSelecionada) => {
    console.log(turmaSelecionada)
    Swal.fire({
      title: 'Confirma a inscrição do candidato na turma para a qual ele está se inscrevendo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
      html: `
      <div 
        style="
          text-align: left; 
          padding: 1rem; 
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
          border-radius: 8px;
          background: rgba(60, 64, 67, 0.1);
          display: flex;
          flex-direction: column;
        "
      >
        <span>Turma selecionada</span>
        <span><strong>Curso:</strong> ${turmaSelecionada?.Curso?.nome || '-'}</span>
        <span><strong>Dia:</strong> ${turmaSelecionada?.dia_da_semana || '-'}</span>
        <span><strong>Início:</strong> ${turmaSelecionada?.horario || '-'}</span>
        <span><strong>Local:</strong> ${turmaSelecionada?.local || '-'}</span>
      </div>
    `,
    }).then((result) => {
      if (result.value) {
        sendAnaliseAndInsertUserInClasses(turmaSelecionada.turmas_id)
      }
    });
  };

  const modalAlertUserReproved = (turmaSelecionada) => {
    console.log(turmaSelecionada)
    Swal.fire({
      title: 'Confirmar a reprovação da análise do aluno?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        sendAnaliseAndReproveUser(turmaSelecionada.turmas_id)
      }
    });
  };

  const removeFileFromBd = async (id) => {
    ApiService.delete(`/documentos/remove/${id}`)
      .then(() => {
        setFiles([]);
        setFilesSelected([]);
        setTextFile(null);
        getCourseHistoryFromSpiritualCenter();
        toast('success', 'Removido com sucesso');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };
  
  const sendAnalise = async () => {
    const analiseData =
      coursesCompatible[0]?.Analise_Centro_Espirita.length > 0;
    const analiseId =
      coursesCompatible[0]?.Analise_Centro_Espirita[0]
        ?.analise_centro_espirita_id;
    const pessoa_que_autorizou_id = localStorage.getItem('storedUserId');
    const values = {
      analise,
      aluno_id: userId,
      centro_espirita_id:
        spiritualCenterSelectedId || spiritualCenterSelectedNewId,
      diretor_id: pessoa_que_autorizou_id,
    };
    if (analiseData) {
      ApiService.put(`/analises/${analiseId}`, values)
        .then(() => {
          getCourseHistoryFromSpiritualCenter();
          toast('success', 'Editado com sucesso');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      ApiService.post(`/analises`, values)
        .then(() => {
          getCourseHistoryFromSpiritualCenter();
          toast('success', 'Adicionado com sucesso');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }
  };

  const sendAnaliseAndInsertUserInClasses = async (turmaId) => {
    const analiseData =
      coursesCompatible[0]?.Analise_Centro_Espirita.length > 0;
    const analiseId =
      coursesCompatible[0]?.Analise_Centro_Espirita[0]
        ?.analise_centro_espirita_id;
    const pessoa_que_autorizou_id = localStorage.getItem('storedUserId');
    const courseHistoryId = coursesCompatible[0]?.Turmas[0]?.Curso_Historico[0]?.curso_historico_id
    const values = {
      analise,
      aluno_id: userId,
      centro_espirita_id:
        spiritualCenterSelectedId || spiritualCenterSelectedNewId,
      diretor_id: pessoa_que_autorizou_id,
      turmaId,
      courseHistoryId,
      cursoIntroId
    };
    console.log(values)
    console.log(analiseId)
    if (analiseData) {
      ApiService.put(`/analises-com-insercao-do-aluno-na-turma/${analiseId}`, values)
        .then(() => {
          getCourseHistoryFromSpiritualCenter();
          toast('success', 'Ação realizada com sucesso');
          setDisabledButton(true)
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast('error', error?.response?.data?.error);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      ApiService.post(`/analises-com-insercao-do-aluno-na-turma`, values)
        .then(() => {
          getCourseHistoryFromSpiritualCenter();
          toast('success', 'Ação realizada com sucesso');
          setDisabledButton(true)
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast('error', error?.response?.data?.error);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }
  };

  const sendAnaliseAndReproveUser = async (turmaId) => {
    console.log('sendAnaliseAndReproveUser turmaId', turmaId) // turmaId é a turma que o aluno selecioonou no ato da matricula
    console.log('coursesCompatible', coursesCompatible)
    const analiseData =
      coursesCompatible[0]?.Analise_Centro_Espirita.length > 0;
    const analiseId =
      coursesCompatible[0]?.Analise_Centro_Espirita[0]
        ?.analise_centro_espirita_id;
    const courseHistoryId = coursesCompatible[0]?.Turmas[0]?.Curso_Historico[0]?.curso_historico_id
    const pessoa_que_autorizou_id = localStorage.getItem('storedUserId');
    const values = {
      analise,
      aluno_id: userId,
      centro_espirita_id:
        spiritualCenterSelectedId || spiritualCenterSelectedNewId,
      diretor_id: pessoa_que_autorizou_id,
      turmaId,
      courseHistoryId,
      cursoIntroId,
    };
    if (analiseData) {

      ApiService.put(`/analises-com-reprovacao/${analiseId}`, values)
        .then(() => {
          // getCourseHistoryFromSpiritualCenter();
          toast('success', 'Ação realizada com sucesso');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast('error', error?.response?.data?.error);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
      } else {
        ApiService.post(`/analises-com-reprovacao`, values)
        .then(() => {
          // getCourseHistoryFromSpiritualCenter();
          toast('success', 'Ação realizada com sucesso');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast('error', error?.response?.data?.error);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
      }

  };

  


  /*  useEffect(() => {
    if (coursesCompatible?.length > 0) {
      const analiseExists =
        coursesCompatible[0]?.Analise_Centro_Espirita[0]?.analise;
      if (analiseExists) {
        setAnalise(analiseExists);
      }
    }
  }, [coursesCompatible, setAnalise]); */

  

    const coursesCompatibleFiltered = coursesCompatible?.filter(
      (item) =>
        item.curso_compativel_id !== null || item?.Turma?.local === 'Externo'
    );

    const columns = [
      {
        name: 'Curso',
        label: 'Curso',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      },
      {
        name: 'Ano',
        label: 'Conclusão',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: () => ({
            align: 'center',
          }),
          setCellProps: () => ({
            align: 'center',
          }),
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      },
      /* {
        name: 'Local',
        label: 'Local',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      }, */
      {
        name: 'Observacao',
        label: 'Comentários',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      },
      /* {
        name: 'data_analise',
        label: 'Data da Análise Realizada',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const createdData =
              coursesCompatibleFiltered[0].Analise_Centro_Espirita[0]
                ?.createdAt;
            const formattedDate = createdData
              ? moment(createdData).format('DD/MM/YYYY')
              : '-';
            return <Typography>{formattedDate}</Typography>;
          },
        },
      }, */
      {
        name: 'Curso_Historico_Id',
        label: 'Ação',
        options: {
          filter: false,
          sort: false,
          display: showButton ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'secondary'}
                disabled={!hasPermission('Diretores', 'deletar') || disabledButton ? true : false}
                onClick={() =>
                  modalAlertDelete(
                    value,
                    spiritualCenterSelectedId || spiritualCenterSelectedNewId
                  )
                }
              >
                <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
                  <DeleteIcon />
                </Tooltip>
              </Button>
            </>
          ),
        },
      },
    ];

    const transformData = (turmas) => {
      return turmas
        ?.filter((turma) => turma?.Curso_Historico.length > 0)
        ?.map((turma) => ({
          Curso: turma.Curso.nome,
          Ano: turma.ano,
          Local: turma?.Centro_Espirita?.nome_fantasia,
          Observacao: turma.Curso_Historico[0]?.observacao,
          Curso_Historico_Id: turma.Curso_Historico[0]?.curso_historico_id,
        }));
    };

    const transformedData = transformData(coursesCompatibleFiltered[0]?.Turmas);

    const createdData =
      coursesCompatibleFiltered[0]?.Analise_Centro_Espirita[0]?.createdAt;
    const formattedDate = createdData
      ? moment(createdData).format('DD/MM/YYYY')
      : '-';

    const renderFooterRow = () => {
      const docs =
        coursesCompatibleFiltered[0]?.Documento_De_Curso_Outra_Entidade;

      return (
        <Paper elevation={1}>
          {/* {transformedData.length > 0 && ( */}
            <Grid container spacing={3} mt={0.5} pl={1}>
              <Grid item xs={8}>
                <JumboTextField
                  fullWidth
                  name='analise'
                  label='Análise realizada'
                  multiline
                  rows={4}
                  value={analise}
                  onChange={(e) => setAnalise(e.target.value)}
                  sx={{ background: '#ffffff' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {
                  pathname === '/app/listar-avaliacao-de-documentos'
                  ?
                    <Select
                      labelId="select-label"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Escolha o status da análise
                      </MenuItem>
                      <MenuItem value="approved">Análise aprovada</MenuItem>
                      <MenuItem value="rejected">Análise reprovada</MenuItem>
                      <MenuItem value="continue">Continuar a análise</MenuItem>
                    </Select>
                  :
                    <Button
                      variant='contained'
                      color='success'
                      onClick={() => { 
                        pathname === '/app/listar-avaliacao-de-documentos'
                        ?
                          modalAlertUserAnalisesAndTransfer(turma)
                        : 
                          sendAnalise()
                      }}
                    >
                      Salvar análise
                      
                    </Button>
                }

                

              </Grid>
              {
                  pathname === '/app/listar-avaliacao-de-documentos'
                  &&
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleAction}
                    sx={{mb: 2}}
                    disabled={!hasPermission('Diretores', 'criar') || disabledButton}
                  >
                    Salvar
                  </Button>
                </Grid>
              }

            </Grid>
         {/*  )} */}
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            gap={3}
            flexDirection={'column'}
            sx={{background: 'rgba(0, 0, 0, 0.05)',  p:1}}
          >
            <Typography>Declaração(ões) da Entidade de Origem</Typography>
            {docs?.map((item) => (
              <FileListItem className='file-item' key={item.nome}>
                <p>
                  <a
                    href={`${process.env.REACT_APP_API_KEY}/images/${item.nome}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {item?.nome_label || item?.nome.replace(/^\d+_/, '')}
                  </a>
                </p>

                <Button
                  variant='contained'
                  size='small'
                  color='secondary'
                  disabled={disabledButton}
                  onClick={() =>
                    modalAlertDeleteFile(
                      item.documento_de_curso_outra_entidade_id
                    )
                  }
                >
                  <DeleteIcon />
                </Button>
              </FileListItem>
            ))}
          </Box>
        </Paper>
      );
    };

    return (
      <>
        <Box mb={4} px={2}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Typography
              variant='h6'
              fontSize={'1rem'}
              fontWeight={'bold'}
              gutterBottom
            >
              Entidade de Origem: {coursesCompatibleFiltered[0]?.nome_fantasia || spiritualCenterName}
            </Typography>
            {coursesCompatibleFiltered[0]?.Analise_Centro_Espirita[0]?.diretor
              ?.nome && (
              <Box display={'flex'} flexDirection={'column'}>
                <Typography
                  variant='h6'
                  fontSize={'1rem'}
                  fontWeight={'bold'}
                  gutterBottom
                >
                  {/* Analisado por:
                  {
                    coursesCompatibleFiltered[0].Analise_Centro_Espirita[0]
                      ?.diretor?.nome
                  } */}
                  
                  {` Analisado por: ${coursesCompatibleFiltered[0]?.Analise_Centro_Espirita[0]
                      ?.diretor?.nome} \u00A0\u00A0\u00A0\u00A0 Em: ${formattedDate}`}
                </Typography>
                {/* <Typography
                  variant='h6'
                  fontSize={'1rem'}
                  fontWeight={'bold'}
                  gutterBottom
                >
                  {`Análise realizada em: ${formattedDate}`}
                </Typography> */}
              </Box>
            )}
          </Box>
          <MUIDataTable
            //title={centro.nome_fantasia || centro.nome}
            data={transformedData}
            columns={columns}
            options={{
              elevation: 1,
              filterType: 'checkbox',
              selectableRows: 'none',
              search: false,
              download: false,
              print: false,
              sortFilterList: false,
              viewColumns: false,
              filter: false,
              pagination: false,
              /* footer: true,
              customFooter: () => renderFooterRow(), */
              textLabels: {
                pagination: {
                  next: 'Próxima Página',
                  previous: 'Página Anterior',
                  rowsPerPage: 'Itens por Página:',
                  displayRows: 'de',
                },
                body: {
                  noMatch: 'Nenhum registro encontrado'
                }
              },
              setTableProps: () => {
                return {
                  size: 'small',
                };
              },
            }}
          />
          {
            renderFooterRow()
          }
        </Box>
        {showButton && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant='contained'
                component='label'
                color='info'
                sx={{
                  height: '53.13px',
                  fontSize: {
                    xs: '0.7rem',
                  },
                  textAlign: 'center',
                }}
                disabled={disabledButton}
              >
                  Adicionar Declarações/Comprovantes
                <input
                  type='file'
                  hidden
                  name='imagem_area'
                  onChange={uploadHandler}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FileListSlim
                files={files}
                removeFile={removeFile}
                isEditingFile={isEditingFile}
                textFile={textFile}
                handleInputChangeFile={handleInputChangeFile}
                handleSaveClick={handleSaveClick}
                handleEditClick={handleEditClick}
                setIsEditingFile={setIsEditingFile}
              />
            </Grid>
            {files.length > 0 && (
              <Grid item xs={12}>
                <Button variant='contained' onClick={() => uploadFile()}>
                Salvar Declaração(ões)/Comprovante(s)

                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
};

export default RequerimentAnalisesForEdit;
