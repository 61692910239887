import React, { useState, useContext, useCallback } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  Accordion,
  Card,
  Tooltip,
  Typography,
  Zoom,
  Grid,
  Chip,
  Box,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import ModalVolunteerInterestedHistory from 'app/components/VolunteersInterested/Modals/ModalVolunteerInterestedHistory';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import { ApiService } from 'app/servicesTwo/ApiService';
import DeleteIcon from '@mui/icons-material/Delete';

export const LinkStyle = styled(Link)`
  padding: 4px 10px;
  background: #7352c7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

export const ButtonStyle = styled(Button)`
  padding: 4px 10px;
  min-width: 30px;
  background: #2eb5c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

const None = styled(Div)`
  @media (max-width: 1280px) {
    display: none;
  }
`;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

const MessageItem = ({
  item,
  messages,
  setMessages
}) => {
  const location = useLocation();
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };


  const { hasPermission } = useContext(PermissionContext);
  const navigate = useNavigate();

  const modalAlert = (id) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMessage(id);
      }
    });
  };

  const removeById = (arr, id) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.mensagem_id === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  };

  const deleteMessage = useCallback(async (id) => {
      try {
        ApiService.delete(`/mensagens/${id}`)
          .then((response) => {
            const updatedList = removeById(messages, id);
            setMessages(updatedList);
            toast('success', 'Removido com sucesso');
          })
          .catch((error) => {
            console.log('error', error.message);
          });
      } catch (err) {
        console.log(err);
        toast('error', 'Ocorreu um erro');
      }
    }, []);


  return (
    <>
      <Card sx={{ mb: 1 }}>
        <Accordion square sx={{ borderRadius: 2 }}>
          <AccordionSummary
            aria-controls='panel1bh-content'
            id='panel1bh-header'
            sx={{
              px: 1,
              flexDirection: 'row-reverse',

              '& .MuiAccordionSummary-content': {
                alignItems: 'center',

                '&.Mui-expanded': {
                  margin: '12px 0',
                },
              },
              '.MuiAccordionSummary-expandIconWrapper': {
                borderRadius: 1,
                border: 1,
                color: 'text.secondary',
                borderColor: 'divider',
                transform: 'none',
                height: 28,
                width: 28,
                alignItems: 'center',
                justifyContent: 'center',
                mr: 1,

                '&.Mui-expanded': {
                  transform: 'none',
                  color: 'primary.main',
                  borderColor: 'primary.main',
                },

                '& svg': {
                  fontSize: '1.25rem',
                },
              },
            }}
          >
            <Grid container spacing={2} alignContent={'center'}>
              <Grid item lg={5} md={4} xs={8}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Curso
                </Typography>
                <EllipsisTooltip text={item?.Curso?.nome} />
              </Grid>
              <Grid item lg={5} md={3} xs={4}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Assunto
                </Typography>
                <EllipsisTooltip text={item.assunto ?? '-'} />
              </Grid>

              <Grid item lg={1} md={2} xs={4}>
                <Tooltip
                  title=' Editar Mensagem'
                  TransitionComponent={Zoom}
                  arrow
                >
                  <LinkStyle
                    to={`/app/editar-mensagem/${item.mensagem_id}`}
                    state={{ backUrl: location.pathname }}
                    style={{
                      background: !hasPermission('Mensagens', 'acessar')
                        ? 'rgba(0, 0, 0, 0.12)'
                        : '#0092D0',
                      pointerEvents: !hasPermission('Mensagens', 'acessar')
                        ? 'none'
                        : '',
                    }}
                  >
                    <VisibilityIcon
                      style={{
                        color: !hasPermission('Mensagens', 'acessar')
                          ? 'rgba(0, 0, 0, 0.26)'
                          : '#ffffff',
                      }}
                    />
                  </LinkStyle>
                </Tooltip>
              </Grid>
              <Grid item lg={1} md={2} xs={4}>
                <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
                  
                    <Button
                      disableElevation
                      variant={'contained'}
                      size={'small'}
                      color={'secondary'}
                      disabled={!hasPermission('Mensagens', 'deletar')}
                      onClick={() => modalAlert(item?.mensagem_id)}
                    >
                      <DeleteIcon />
                    </Button>
                  
                </Tooltip>
                </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      </Card>
    </>
  );
};
/* Todo item prop define */

export default MessageItem;
