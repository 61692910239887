import React, { useEffect } from 'react';
import { EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import JumboDemoCard from '@jumbo/components/JumboDemoCard';

let Editor = () => <React.Fragment />;
const EmailEditor = ({ editorState, setEditorState, title }) => {
  useEffect(() => {
    Editor = require('react-draft-wysiwyg').Editor;
    setEditorState(EditorState.createEmpty());
  }, []);

  return (
    <JumboDemoCard title={title}>
      <Editor
        wrapperStyle={{ width: '100%' }}
        editorStyle={{
          width: '100%',
          minHeight: 200,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'lightgray',
        }}
        editorState={editorState}
        onEditorStateChange={(editorState) => setEditorState(editorState)}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'history',
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
        localization={{ locale: 'pt' }}
      />
    </JumboDemoCard>
  );
};

export default EmailEditor;
