import React, { useContext } from 'react';
import {useLocation} from "react-router-dom";
import {isUrlInChildren} from "@jumbo/utils/urlHelpers";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import {ArrowWrapper} from "@jumbo/components/JumboVerticalNavbar/style";
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import {Popover} from "@mui/material";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import {SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useTranslation} from "react-i18next";
import { PermissionContext } from 'app/contexts/PermissionContext';

const menuBefore = {
    left: 0,
    top: 0,
    content: `''`,
    position: 'absolute',
    display: 'inline-block',
    width: '4px',
    height: '100%',
    backgroundColor: 'transparent'
};

const JumboNavCollapsible = ({item, translate}) => {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopover = Boolean(anchorEl);
    const {t} = useTranslation();
    const { hasPermission } = useContext(PermissionContext);

    const location = useLocation();
    const [previousPath, setPreviousPath] = React.useState(location.pathname);

    const {sidebarOptions} = useJumboLayoutSidebar();
    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    const handlePopoverOpen = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handlePopoverClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    React.useEffect(() => {
        if (previousPath !== location.pathname) {
            setPreviousPath(location.pathname);
        }
    }, [item, location.pathname, previousPath]);
    

    React.useEffect(() => {
        if(location.pathname.startsWith('/app/listar') || location.pathname.startsWith('/app/log')) {
            setOpen(isUrlInChildren(item, previousPath));
        } else {
            setOpen(isSubmenuOpen(item.children));
        }
    }, [item, previousPath]);

    const isSubmenuOpen = (submenu) => {
        const routeMappings = [
            //inicio sessão Admin
            {
                route: '/app/novo-diretor',
                label: 'Diretores'
            },
            {
                route: '/app/editar-diretor',
                label: 'Diretores'
            },
            {
                route: '/app/nova-sala',
                label: 'Salas'
            },
            {
                route: '/app/editar-sala',
                label: 'Salas'
            },
            {
                route: '/app/nova-area',
                label: 'Áreas',
            },
            {
                route: '/app/editar-area',
                label: 'Áreas',
            },
            {
                route: '/app/novo-departamento',
                label: 'Departamentos',
            },
            {
                route: '/app/editar-departamento',
                label: 'Departamentos',
            },
            {
                route: '/app/novo-perfil',
                label: 'Perfis',
            },
            {
                route: '/app/editar-perfil',
                label: 'Perfis',
            },
            {
                route: '/app/novo-usuario',
                label: 'Usuários',
            },
            {
                route: '/app/editar-usuario',
                label: 'Usuários',
            },
            {
                route: '/app/novo-abrir-inscricoes',
                label: 'Setup de Novo Curso',
            },
            {
                route: '/app/editar-abrir-inscricoes',
                label: 'Setup de Novo Curso',
            },
            //fim sessão Admin

            //inicio sessão Pessoas
            {
              route: '/app/novo-aluno',
              value: 'Cadastro Único',
            },
            {
              route: '/app/editar-aluno/',
              value: 'Cadastro Único',
            },
            {
              route: '/app/novo-associado',
              value: 'Associados',
            },
            {
              route: '/app/editar-associado/',
              value: 'Associados',
            },
            {
              route: '/app/novo-conselho',
              value: 'Conselho Deliberativo',
            },
            {
              route: '/app/editar-conselho/',
              value: 'Conselho Deliberativo',
            },
            //fim sessão Pessoas

            //inicio sessão Ensino
            {
                route: '/app/novo-curso',
                label: 'Cursos',
            },
            {
                route: '/app/editar-curso',
                label: 'Cursos',
            },
            {
                route: '/app/nova-turma',
                label: 'Turmas',
            },
            {
                route: '/app/editar-turma',
                label: 'Turmas',
            },
            {
                route: '/app/listar-turmas-inserir-aluno',
                label: 'Inclusão de Alunos na Turma',
            },
            {
                route: '/app/inserir-aluno-turma',
                label: 'Inclusão de Alunos na Turma',
            },
            {
                route: '/app/novo-abrir-inscricoes',
                label: 'Setup de Curso no Ano',
            },
            {
                route: '/app/editar-abrir-inscricoes',
                label: 'Setup de Curso no Ano',
            },
            //fim sessão Ensino

            //inicio sessão Voluntariado
            {
                route: '/app/novo-voluntariado',
                value: 'Voluntários',
            },
            {
                route: '/app/editar-voluntariado',
                value: 'Voluntários',
            },
            {
                route: '/app/editar-alocar-voluntariado',
                value: 'Voluntários',
            },
            {
                route: '/app/nova-equipe',
                value: 'Livros (Equipe)',
            },
            {
                route: '/app/editar-equipe',
                value: 'Livros (Equipe)',
            },
            {
                route: '/app/inserir-voluntario-livro',
                value: 'Inclusão de Voluntários na Equipe',
            },
            {
                route: '/app/novo-interesse-voluntariado',
                value: 'Interesse Voluntariado',
            },
            {
                route: '/app/editar-interesse-voluntariado-resume',
                value: 'Interesse Voluntariado',
            },
            {
                route: '/app/impressao-de-crachas',
                value: 'Impressão de Crachás',
            }

            //fim sessão Voluntariado
        ];
        
        const matchedRoute = routeMappings.some((mapping) => {
            for (let i = 0; i < submenu.length; i++) {
              if (submenu[i].value) {
                if (location.pathname.includes(mapping.route) && submenu[i].value === mapping.value) {
                  return true;
                }
              } else {
                if (location.pathname.includes(mapping.route) && submenu[i].label === mapping.label) {
                  return true;
                }
              }
            }
            return false;
        });

        return !!matchedRoute;
    };
      
   

    // console.log(item)
    const label = React.useMemo(() => {
        item.children.map((i) => {
            if(i.uri === '/app/listar-interesse-voluntariado') {
                i.label = 'Voluntariado'
                i.value = 'Interesse Voluntariado'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            }
            if (i.uri === '/app/listar-voluntariado'){
                i.label = 'Voluntariado'
                i.value = 'Voluntários'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            }

            if (i.uri === '/app/listar-alocar-voluntariado'){
                i.label = 'Voluntariado'
                i.value = 'Alocação de Voluntários'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            }

           /*  if (i.uri === '/app/listar-equipes'){
                i.label = 'Voluntariado'
                i.value = 'Equipe'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            }

            if (i.uri === '/app/listar-equipes-inserir-voluntario'){
                i.label = 'Voluntariado'
                i.value = 'Inclusão de Voluntários na Equipe'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            } */

            if (i.uri === '/app/impressao-de-crachas'){
                i.label = 'Voluntariado'
                i.value = 'Impressão de Crachás'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            }

            if(i.uri === '/app/listar-usuarios') {
                i.label = 'Pessoas'
                i.value = 'Cadastro Único'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            }
            if(i.uri === '/app/listar-associados') {
                i.label = 'Associados'
                i.value = 'Associados'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            }
            if(i.uri === '/app/listar-conselhos') {
                i.label = 'Conselho Deliberativo'
                i.value = 'Conselho Deliberativo'
                
                return hasPermission(t(i.label), 'acessar') ? t(i.value) : null;
            }
        
            return null;
        });

        return hasPermission(t(item.label), 'acessar') ? t(item.label) : (
            item.children.some(y => hasPermission(y.label, 'acessar')) ? t(item.label) : null
        );
    }, [item, translate, t]);

    if (!item)
        return null;

    const subMenus = (item.children && item.children.length > 0) ? item.children : null;
    
    return (
        <React.Fragment>
            { label 
                ?
                (
                    <>
                        <ListItemButton
                            component={"li"}
                            onClick={() => setOpen(!open)}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            sx={{
                                p: theme => !isMiniAndClosed ? theme.spacing(1, 3.75) : 0,
                                borderRadius: isMiniAndClosed ? '50%' : '0 24px 24px 0',
                                margin: isMiniAndClosed ? '0 auto' : '0',
                                ...(isMiniAndClosed) ? {width: 40, height: 40, justifyContent: 'center'} : {},
                                overflow: 'hidden',
                                '&:hover': {
                                    color: theme => theme.palette.nav.action.hover,
                                    backgroundColor: theme => theme.palette.nav.background.hover,
                                    ...(!isMiniAndClosed) ? {'&::before': {
                                            ...menuBefore,
                                            backgroundColor: theme => theme.palette.nav.tick.hover,
                                        }} : {}
                                },
                                ...(!isMiniAndClosed) ? {'&::before': menuBefore} : {},
                            }}
                        >
                            {
                                !isMiniAndClosed &&
                                <ArrowWrapper>
                                    {
                                        open ? <ArrowDropDownIcon/> : <ArrowRightIcon/>
                                    }
                                </ArrowWrapper>
                            }
                            {
                                item.icon &&
                                <ListItemIcon sx={{minWidth: isMiniAndClosed ? 20 : 32, color: 'inherit'}}>
                                    {item.icon}
                                </ListItemIcon>
                            }
                            {
                                !isMiniAndClosed &&
                                <ListItemText
                                    primary={label}
                                    sx={{
                                        m: 0,
                                        '& .MuiTypography-root': {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }
                                    }}
                                />
                            }
                        </ListItemButton>
                        {
                            subMenus && !isMiniAndClosed &&
                            <Collapse component={"li"} in={open} timeout="auto" unmountOnExit>
                                <List disablePadding>
                                    {
                                        subMenus.map((child, index) => {
                                            const eventKey = new Date().valueOf();
                                            if(hasPermission(t(child.label), 'acessar')) {

                                                return <JumboNavIdentifier item={child} key={`${eventKey}${index}`} isNested={true}/>
                                            }
                                           
                                        })
                                    }
                                </List>
                            </Collapse>
                        }
                        {
                            subMenus && isMiniAndClosed &&
                            <Popover
                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: 'none',
                                }}
                                open={openPopover}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <List disablePadding>
                                    {
                                        subMenus.map((child, index) => {
                                            const eventKey = new Date().valueOf();
                                            return <JumboNavIdentifier item={child} key={`${eventKey}${index}`} isNested={true}/>
                                        })
                                    }
                                </List>
                            </Popover>
                        }
                    </>
                )
                : null
            }
            
        </React.Fragment>
    )
};

export default JumboNavCollapsible;
