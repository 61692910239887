import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import { Accordion, Card, Tooltip, Typography, Grid, Chip, Modal, Fade, Backdrop, Box } from "@mui/material";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Zoom from '@mui/material/Zoom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { useLocation, useNavigate } from 'react-router-dom'
import Div from '@jumbo/shared/Div/Div';
import ModalAuthorizeFile from '../coursesIntro/ModalAuthorizeFile';
import ModalTransferClassEvaluate from './ModalTransferClassEvaluate';
import MatchCandidate from 'app/components/MatchCandidate/MatchCandidate';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import ModalCourseHistory from '../course_histories/ModalCourseHistory';
import HistoryIcon from '@mui/icons-material/History';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ModalAddDocs from "../course_histories/ModalAddDocs";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',

};

export const getChipColor = (diaDaSemana) => {
    switch (diaDaSemana) {
        case 'Segunda':
            return 'primary';
        case 'Terça':
            return 'secondary';
        case 'Quarta':
            return 'success';
        case 'Quinta':
            return 'success';
        case 'Sexta':
            return 'info';
        case 'Sábado':
            return 'error';

        default:
            return 'default';
    }
};

const EvaluateDocumentsItem = ({ item, setUsersRegistration, usersRegistration, getClasses, classes, setClasses, page, perPage, handleFilter, yearSelected, searchTerm }) => {
    // console.log(classes)
    // console.log(item)

    const location = useLocation();
    const { hasPermission } = useContext(PermissionContext);
    const [open, setOpen] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [openAddDocs, setOpenAddDocs] = useState(false);
    const [openModalTransferClass, setOpenModalTransferClass] = useState(false);

    const navigate = useNavigate();

    const Swal = useSwalWrapper();
    const toast = (variant, message, timer = undefined) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            timer: timer,
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const modalAlert = (curso_introdutorio_historico_id) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                removeStudentFromCourseIntro(curso_introdutorio_historico_id);
            }
        });
    };

    const modalAlertInsert = (userInfo) => {
        // console.log({userInfo})
        Swal.fire({
            title: 'Tem certeza que deseja incluir na turma?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                transferStudentToClass(userInfo);
            }
        });
    };

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.curso_introdutorio_historico_id === id);

        if (objWithIdIndex > -1) {
            arr.splice(objWithIdIndex, 1);
        }

        return arr;
    }

    function updateById(arr, id) {
        arr.find((entry) => entry.curso_introdutorio_historico_id === id)

        return arr;
    }


    const removeStudentFromCourseIntro = useCallback(async (curso_introdutorio_historico_id) => {
        try {
            ApiService.delete(`/cursos-introdutorios/pessoa/${curso_introdutorio_historico_id}`,)
                .then((response) => {
                    /* 
                        const updatedStudentsArray = removeById(classes, curso_introdutorio_historico_id)
                        setClasses(updatedStudentsArray); */
                    /*  const searchParams = {
                         yearSelected,
                         searchTerm,
                         page, perPage,
                     }
                     getClasses(searchParams) */
                    handleFilter()
                    toast('success', 'Removido com sucesso', 5000);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                })
        } catch (err) {
            console.log(err);
            toast('error', 'Ocorreu um erro');
        }
    }, []);

    const transferStudentFromCourseIntro = useCallback(async (classesInfo, userInfo) => {

        const turmaId = classesInfo.turmas_id
        const pessoa_id = userInfo.pessoa_id
        const introHistoricoId = userInfo.curso_introdutorio_historico_id
        try {
            ApiService.post(`/turmas-inserir/${turmaId}/aluno/${pessoa_id}/intro/${introHistoricoId}`,)
                .then((response) => {
                    toast('success', 'Adicionado com sucesso');
                    const status = response.data.status
                    const updatedStudentsArray = updateById(usersRegistration, introHistoricoId, status)
                    setUsersRegistration(updatedStudentsArray);
                    // getClasses()
                })
                .catch((error) => {
                    if (error.response) {
                        toast('error', error.response.data.error);
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                })
        } catch (err) {
            console.log(err);
            toast('error', 'Ocorreu um erro');
        }
    }, [usersRegistration, setUsersRegistration]);

    const transferStudentToClass = useCallback(async (userInfo) => {

        const turmaId = userInfo.turmas_id
        const pessoa_id = userInfo.pessoa_id
        const introHistoricoId = userInfo.curso_introdutorio_historico_id
        try {
            ApiService.post(`/transferir/aluno/${pessoa_id}/turma/${turmaId}/intro/${introHistoricoId}`,)

                .then((response) => {
                    /* const updatedStudentsArray = removeById(classes, introHistoricoId)
                    setClasses(updatedStudentsArray); */
                    handleFilter()
                    toast('success', 'Transferido com sucesso', 5000);
                })
                .catch((error) => {
                    if (error.response) {
                        toast('error', error.response.data.error);
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                })
        } catch (err) {
            console.log(err);
            toast('error', 'Ocorreu um erro');
        }
    }, [usersRegistration, setUsersRegistration, page, perPage, yearSelected, searchTerm]);

    const maxLengthText = 20;


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        const searchParams = {
            yearSelected,
            searchTerm,
            page,
            perPage,
        };
        getClasses(searchParams)
        console.log("fechou modal")
    };

    const handleOpenHistory = () => setOpenHistory(true);
    const handleCloseHistory = () => setOpenHistory(false);
    const handleOpenAddDocs = () => setOpenAddDocs(true);
    const handleCloseAddDocs = () => {
        setOpenAddDocs(false)
        const searchParams = {
            yearSelected,
            searchTerm,
            page,
            perPage,
        };
        getClasses(searchParams)
        console.log("fechou modal")
    };

    const handleOpenModalTransferClass = () => setOpenModalTransferClass(true);
    const handleCloseModalTransferClass = () => setOpenModalTransferClass(false);



    return (
        <>
            <Card sx={{ mb: 1 }}>
                <Accordion square sx={{ borderRadius: 2 }}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"

                        sx={{
                            px: 3,
                            flexDirection: 'row-reverse',

                            '& .MuiAccordionSummary-content': {
                                alignItems: 'center',

                                '&.Mui-expanded': {
                                    margin: '12px 0',
                                }
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                borderRadius: 1,
                                border: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                transform: 'none',
                                height: 28,
                                width: 28,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 1,

                                '&.Mui-expanded': {
                                    transform: 'none',
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                },

                                '& svg': {
                                    fontSize: '1.25rem',
                                }
                            }
                        }}
                    >
                        <Grid container spacing={3} alignContent={'center'} alignItems="center">
                            <Grid item lg={1} md={2} xs={4}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Ano
                                </Typography>
                                <Typography variant={"body1"}>{item?.Turma?.ano}</Typography>
                            </Grid>
                            <Grid item lg={3} md={4} xs={6}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Nome
                                </Typography>
                                <Typography variant={"body1"}>{item.User.nome}</Typography>
                            </Grid>

                            <Grid item lg={3} md={3} xs={2} sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Curso
                                </Typography>
                                <Typography variant={"body1"}>
                                    <EllipsisTooltip text={item?.Turma?.Curso?.nome} />
                                </Typography>
                            </Grid>

                            <Grid item lg={2} md={3} xs={2} sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}>
                                {item.Turma && (
                                    <Typography variant={"body1"}>
                                        <EllipsisTooltip text={`${item.Turma.horario} - ${item.Turma.dia_da_semana} - ${item.Turma.modo}`} />
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={3} md={4} xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    gap="0.5rem"
                                    sx={{ flexWrap: 'wrap' }}
                                >
                                    <Grid item lg={2} md={2} xs={12}>
                                            {
                                            (item.status === null || item.status_curso_introdutorio_historico === null) && !item.User.atende_requisitos
                                            ?
                                                <Tooltip 
                                                    title="Aluno não atende pré-requisito"
                                                    TransitionComponent={Zoom}
                                                    arrow
                                                >
                                                    <InfoIcon color='error'/>
                                                </Tooltip>
                                            : (item.status === 'reprovado')
                                                ?
                                                <Tooltip 
                                                    title="Análise reprovada"
                                                    TransitionComponent={Zoom}
                                                    arrow
                                                >
                                                    <InfoIcon color=''/>
                                                </Tooltip>
                                                :
                                                    <Tooltip 
                                                        title="Aluno com pré-requisito. Clique para transferir para a turma"
                                                        TransitionComponent={Zoom}
                                                        arrow
                                                    >
                                                        <Button 
                                                            sx={{minWidth: 1}} 
                                                            disableElevation 
                                                            variant={"contained"} 
                                                            size={"small"} 
                                                            color={"info"}
                                                            onClick={() => modalAlertInsert(item)}
                                                        >
                                                            <SwapHorizIcon />
                                                        </Button>

                                                    </Tooltip>
                                        }
                                    </Grid>

                                    <Grid item lg={2} md={2} xs={12}>

                                        <Tooltip
                                            title="Realizar análise"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button
                                                sx={{ minWidth: 1 }}
                                                disableElevation
                                                variant="contained"
                                                size="small"
                                                color={
                                                    item?.Documento_Do_Candidato?.length > 0 || 
                                                    item?.User.Documento_De_Curso_Outra_Entidade?.length > 0 ? "success" : "warning"}
                                                onClick={handleOpen}
                                            >
                                                <WarningIcon />
                                            </Button>
                                        </Tooltip>
                                    </Grid>

                                    <Grid item lg={2} md={2} xs={12}>
                                        <Tooltip
                                            title="Incluir documentos faltantes"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button
                                                sx={{ minWidth: 1 }}
                                                disableElevation
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                onClick={handleOpenAddDocs}
                                            >
                                                <InsertDriveFileIcon />
                                            </Button>
                                        </Tooltip>
                                    </Grid>

                                    <Grid item lg={2} md={2} xs={12}>
                                        <Tooltip
                                            title="Visualizar Histórico"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button
                                                sx={{ minWidth: 1 }}
                                                disableElevation
                                                variant="contained"
                                                size="small"
                                                color="info"
                                                onClick={handleOpenHistory}
                                            >
                                                <HistoryIcon />
                                            </Button>
                                        </Tooltip>
                                    </Grid>


                                    <Grid item lg={2} md={2} xs={12}>

                                        <Tooltip
                                            title="Excluir"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button
                                                sx={{ minWidth: 1 }}
                                                disableElevation
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                disabled={!hasPermission('Livros (Equipe)', 'deletar')}
                                                onClick={() => modalAlert(item?.curso_introdutorio_historico_id)}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Box>
                            </Grid>

                        </Grid>

                    </AccordionSummary>
                </Accordion>
            </Card>

            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={openAddDocs}
                onClose={handleCloseAddDocs}
                closeAfterTransition
            >
                <Fade in={openAddDocs}>
                    <Div sx={style}>
                        <ModalAddDocs
                            user={item?.User}
                            username={item?.User?.nome}
                            userId={item?.User?.pessoa_id}
                            handleClose={handleCloseAddDocs}
                            curso_introdutorio_historico_id={item?.curso_introdutorio_historico_id}
                        />
                    </Div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={openHistory}
                onClose={handleCloseHistory}
                closeAfterTransition
            >
                <Fade in={openHistory}>
                    <Div sx={style}>
                        <ModalCourseHistory
                            user={item?.User}
                            username={item?.User?.nome}
                            userId={item?.User?.pessoa_id}
                            handleClose={handleCloseHistory}
                        />
                    </Div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Div sx={style}>
                        {/* <ModalAuthorizeFile 
                            data={item} 
                            curso_historico_id={item.curso_historico_id} 
                            handleClose={handleClose} 
                            classStudents={classes} 
                            setClassStudents={setClasses}
                            getCourseById={getClasses}
                        /> */}
                        <MatchCandidate
                            item={item}
                            username={item.User.nome}
                            userId={item.User.pessoa_id}
                            handleClose={handleClose}
                        />
                    </Div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModalTransferClass}
                onClose={handleCloseModalTransferClass}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModalTransferClass}>
                    <Div sx={style}>
                        <ModalTransferClassEvaluate
                            data={item}
                            transferStudentFromCourseIntro={transferStudentFromCourseIntro}
                        />
                    </Div>
                </Fade>
            </Modal>
        </>
    );
};
/* Todo item prop define */
export default EvaluateDocumentsItem;
