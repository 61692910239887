import React from 'react';
import { Box, Typography, TableCell } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import capitalize from 'app/utils/capitalize';

const CourseApprovalList = ({ courseHistoryOnlyApproval }) => {
  const columnsRegular = [
    {
      name: 'Turma',
      label: 'Nome',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: 'left',
          width: '40%',
        }),
        setCellProps: () => ({
          align: 'left',
          width: '40%',
        }),
        customHeadRender: (
          columnMeta,
          handleToggleColumn,
          sortOrder,
          sortColumn
        ) => (
          <TableCell width={'40%'} align='left'>
            Nome
          </TableCell>
        ),
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          const item = courseHistoryOnlyApproval[index].Turma
            ? courseHistoryOnlyApproval[index].Turma?.Curso.nome
            : courseHistoryOnlyApproval[index].curso_compativel_nome;
          return <Typography variant={'body1'}>{item}</Typography>;
        },
      },
    },
    {
      name: 'Turma.ano',
      label: 'Conclusão',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value) => (
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        ),
      },
    },
    /* {
      name: 'Turma',
      label: 'Local (FEESP ou Outro centro)',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value) => (
          <Typography variant={'body1'}>{value?.local ?? '-'}</Typography>
        ),
      },
    }, */
    {
      name: 'Turma',
      label: 'Local',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
          width: '25%',
        }),
        setCellProps: () => ({
          align: 'center',
          width: '25%',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>
              {value?.Centro_Espirita?.nome_fantasia ||
                capitalize(
                  value.local === 'Sede'
                    ? 'Sede Maria Paula'
                    : value.local === 'sede_santo_amaro'
                    ? 'Sede Santo Amaro'
                    : value.local
                )}
            </Typography>
          </>
        ),
      },
    },
  ];

  const optionsRegular = {
    filterType: 'checkbox',
    selectableRows: 'none',
    search: false,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false,
    filter: false,
    enableNestedDataAccess: '.',
    textLabels: {
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Itens por Página:',
        displayRows: 'de',
      },
    },
    setTableProps: () => {
      return {
        size: 'small',
      };
    },
  };

  const componentsRegular = {
    ExpandButton: function (props) {
      return <div style={{ width: '24px' }} />;
    },
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={1}
      marginTop='2rem'
      marginBottom='1rem'
    >
      <Typography variant={'h4'} textAlign={'center'}>
        Cursos Aprovados (para maiores informações consulte Cursos Detalhados
        e/ou Cursos Compatibilizados)
      </Typography>
      <Typography variant={'h4'} margin={0}>
        Cursos Regulares
      </Typography>
      {courseHistoryOnlyApproval.length > 0 ? (
        <MUIDataTable
          data={courseHistoryOnlyApproval}
          columns={columnsRegular}
          options={optionsRegular}
          components={componentsRegular}
        />
      ) : (
        <Typography>Nenhum curso encontrado</Typography>
      )}
    </Box>
  );
};

export default CourseApprovalList;
