import React, { useState, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  Accordion,
  Card,
  Tooltip,
  Typography,
  Zoom,
  Grid,
  Chip,
  Box,
  Modal,
  Backdrop,
  Fade,
  useMediaQuery,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { useLocation } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/History';
import { Form, Formik } from 'formik';
import ModalVolunteerInterestedHistory from 'app/components/VolunteersInterested/Modals/ModalVolunteerInterestedHistory';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CrisisAlert from '@mui/icons-material/CrisisAlert';
import { useJumboTheme } from '@jumbo/hooks';

export const LinkStyle = styled(Link)`
  padding: 4px 10px;
  background: #7352c7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

export const ButtonStyle = styled(Button)`
  padding: 4px 10px;
  min-width: 30px;
  background: #2eb5c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

const None = styled(Div)`
  @media (max-width: 1280px) {
    display: none;
  }
`;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
};

const EnrollmentScreenItem = ({ item }) => {
  const location = useLocation();
  const Swal = useSwalWrapper();

  const { theme } = useJumboTheme();

  const lgDown = useMediaQuery(theme.breakpoints.down('md'));

  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  function removeById(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.pessoa_id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  }

  const { hasPermission } = useContext(PermissionContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openHours, setOpenHours] = useState(false);

  const handleOpen = () => {
    console.log(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenHours = () => {
    console.log(item);
    setOpenHours(true);
  };
  const handleCloseHours = () => {
    setOpenHours(false);
  };

  const presencial_sede = item.Turma?.filter((item) => item?.modo === 'Presencial' && item?.local === 'Sede');
  const presencial_casa = item.Turma?.filter((item) => item?.modo === 'Presencial' && item?.local === 'Casa Transitória');
  const presencial_santo_amaro = item.Turma?.filter((item) => item?.modo === 'Presencial' && item?.local === 'sede_santo_amaro');

  const hibrido_sede = item.Turma?.filter((item) => item?.modo === 'Hibrido' && item?.local === 'Sede');

  const hibrido_casa = item.Turma?.filter((item) => item?.modo === 'Hibrido' && item?.local === 'Casa Transitória');

  const hibrido_sede_santo_amaro = item.Turma?.filter((item) => item?.modo === 'Hibrido' && item?.local === 'sede_santo_amaro');


  const virtual = item.Turma?.filter((item) => item?.modo === 'EAD');

  console.log('presencial_sede',presencial_sede)
  console.log('presencial_casa',presencial_casa)
  console.log('presencial_santo_amaro', presencial_santo_amaro)

  console.log('hibrido_sede', hibrido_sede)
  console.log('hibrido_casa', hibrido_casa)
  console.log('hibrido_sede_santo_amaro', hibrido_sede_santo_amaro)

  console.log('virtual',virtual)

  return (
    <>
      <Card sx={{ mb: 1, width: '100%' }}>
        <Accordion square sx={{ borderRadius: 2 }}>
          <AccordionSummary
            aria-controls='panel1bh-content'
            id='panel1bh-header'
            sx={{
              px: 1,
              flexDirection: 'row-reverse',

              '& .MuiAccordionSummary-content': {
                alignItems: 'center',

                '&.Mui-expanded': {
                  margin: '12px 0',
                },
              },
              '.MuiAccordionSummary-expandIconWrapper': {
                borderRadius: 1,
                border: 1,
                color: 'text.secondary',
                borderColor: 'divider',
                transform: 'none',
                height: 28,
                width: 28,
                alignItems: 'center',
                justifyContent: 'center',
                mr: 1,

                '&.Mui-expanded': {
                  transform: 'none',
                  color: 'primary.main',
                  borderColor: 'primary.main',
                },

                '& svg': {
                  fontSize: '1.25rem',
                },
              },
            }}
          >
            <Grid container spacing={2} alignContent={'center'}>
              <Grid item md={6} xs={12}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Curso
                </Typography>
                <EllipsisTooltip text={item.Turma[0]?.Curso?.nome} font={!lgDown ? '1.3rem' : undefined} fontWeight={'bold'}/>
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Pré requisito
                </Typography>
                {/* <EllipsisTooltip
                  text={item.Turma[0]?.Curso?.Curso?.length > 0 ? 'Sim' : 'Não'}
                /> */}
                <Tooltip
                  title='Clique para obter detalhes'
                  TransitionComponent={Zoom}
                  arrow
                >
                  <Button
                    variant='contained'
                    size='small'
                    color={
                      item.Turma[0]?.Curso?.Curso?.length > 0
                        ? 'info'
                        : 'success'
                    }
                    onClick={handleOpen}
                  >
                    {item.Turma[0]?.Curso?.Curso?.length > 0 ? 'Sim' : 'Não'}
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item md={2} xs={12}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Horário
                </Typography>
                {/* <EllipsisTooltip
                  text={item.Turma[0]?.Curso?.Curso?.length > 0 ? 'Sim' : 'Não'}
                /> */}
                <Tooltip
                  title='Clique para obter detalhes'
                  TransitionComponent={Zoom}
                  arrow
                >
                  <Button
                    variant='contained'
                    size='small'
                    color={'info'}
                    onClick={handleOpenHours}
                  >
                    Horário
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item md={2} xs={12}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Clique para se Inscrever
                </Typography>
                <Tooltip
                  title='Clique para se inscrever'
                  TransitionComponent={Zoom}
                  arrow
                >
                  <Button
                    variant='contained'
                    size='small'
                    color='info'
                    /*  startIcon={<AssignmentTurnedInIcon />} */
                    onClick={() =>
                      /* window.open(
                        `/inscricao-curso/${item.curso_indrodutorio_id}/turma/${item.Turma[0]?.turmas_id}`,
                        '_blank'
                      ) */
                      navigate(
                        `/inscricao-curso/${item.curso_indrodutorio_id}/turma/${item.Turma[0]?.turmas_id}`,
                        {
                          state: {
                            backUrl: `${location.pathname}`,
                          },
                        }
                      )
                    }
                  >
                    <CrisisAlert />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>

        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box
              sx={{
                ...style,
                p: 4,
                maxWidth: 600,
                margin: 'auto',
                backgroundColor: 'white',
                borderRadius: 2,
                width: '100%',
              }}
            >
              <Box display={'flex'} justifyContent={'end'} mb={3}>
                <Button
                  size='small'
                  variant='contained'
                  color='success'
                  onClick={handleClose}
                >
                  Voltar
                </Button>
              </Box>
              <Typography
                variant='h6'
                id='transition-modal-title'
                sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}
              >
                {item.Turma[0]?.Curso?.nome}
              </Typography>

              {/* <Typography variant='body1' sx={{ mb: 2 }}>
                <strong>Duração da aula:</strong> 2h
              </Typography> */}
              <Typography variant='body1' sx={{ mb: 2 }}>
                <strong>Pré-requisito:</strong>{' '}
                {item.Turma[0]?.Curso?.Curso?.length > 0
                  ? 'ter concluído ' +
                    item.Turma[0]?.Curso?.Curso[0]?.pre_requisito?.nome
                  : 'Não há requisitos'}
              </Typography>
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={openHours}
          onClose={handleCloseHours}
          closeAfterTransition
        >
          <Fade in={openHours}>
            <Box
              sx={{
                ...style,
                p: 4,
                maxWidth: 600,
                margin: 'auto',
                backgroundColor: 'white',
                borderRadius: 2,
                width: '100%',
                height: '100%', overflowY: 'auto'
              }}
            >
              <Box display={'flex'} justifyContent={'end'} mb={3}>
                <Button
                  size='small'
                  variant='contained'
                  color='success'
                  onClick={handleCloseHours}
                >
                  Voltar
                </Button>
              </Box>
              <Typography
                variant='h5'
                id='transition-modal-title'
                sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}
              >
                {item.Turma[0]?.Curso?.nome}
              </Typography>

              <Typography
                variant='h5'
                sx={{ 
                  fontWeight: '500',
                  marginBottom: 2
              }}>
                Horários:
              </Typography>

              {/* Horários presenciais */}
              {presencial_sede.length > 0 && (
                <>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: '500', 
                        my: 1,
                        color: '#7352c7',
                         
                    }}
                  >
                    Presencial na {''}
                    <span style={{ textDecoration: 'underline' }}>
                      SEDE MARIA PAULA 
                    </span>
                    <span style={{color: '#37373C'}}>
                    {''} (Rua Maria Paula, 140, Centro, SP):
                    </span>
                  </Typography>

                  <ul>
                    {presencial_sede.map((el, index) => (
                      <li key={index} style={{ fontSize: '15px' }}>
                        {`${el.dia_da_semana} - ${el.horario}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {presencial_casa.length > 0 && (
                <>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: '500', 
                        my: 1,
                        color: '#7352c7',
                         
                    }}
                  >
                    Presencial na {''}
                    <span style={{ textDecoration: 'underline' }}>
                      CASA TRANSITÓRIA
                    </span>
                    <span style={{color: '#37373C'}}>
                    {''} (Av. Condessa Elizabeth de Robiano – 454 – Belenzinho – SP):
                    </span>
                  </Typography>

                  <ul>
                    {presencial_casa.map((el, index) => (
                      <li key={index} style={{ fontSize: '15px' }}>
                        {`${el.dia_da_semana} - ${el.horario}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {presencial_santo_amaro.length > 0 && (
                <>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: '500', 
                        my: 1,
                        color: '#7352c7',
                         
                    }}
                  >
                    Presencial na {''}
                    <span style={{ textDecoration: 'underline' }}>
                      SEDE SANTO AMARO
                    </span>
                    <span style={{color: '#37373C'}}>
                    {''} (Rua Santo Amaro, 370, Bela Vista):
                    </span>
                  </Typography>

                  <ul>
                    {presencial_santo_amaro.map((el, index) => (
                      <li key={index} style={{ fontSize: '15px' }}>
                        {`${el.dia_da_semana} - ${el.horario}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {/* Horários hibridos */}
              {hibrido_sede.length > 0 && (
                <>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: '500', 
                        my: 1,
                        color: '#7352c7',
                         
                    }}
                  >
                    Híbrido na {''}
                    <span style={{ textDecoration: 'underline' }}>
                      SEDE MARIA PAULA
                    </span>
                    <span style={{color: '#37373C'}}>
                    {''} (Rua Maria Paula, 140, Centro, SP):
                    </span>
                  </Typography>

                  <ul>
                    {hibrido_sede.map((el, index) => (
                      <li key={index} style={{ fontSize: '15px' }}>
                        {`${el.dia_da_semana} - ${el.horario}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {hibrido_casa.length > 0 && (
                <>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: '500', 
                        my: 1,
                        color: '#7352c7',
                         
                    }}
                  >
                    Híbrido na {''}
                    <span style={{ textDecoration: 'underline' }}>
                      CASA TRANSITÓRIA
                    </span>
                    <span style={{color: '#37373C'}}>
                    {''} (Av. Condessa Elizabeth de Robiano – 454 – Belenzinho – SP):
                    </span>
                  </Typography>

                  <ul>
                    {hibrido_casa.map((el, index) => (
                      <li key={index} style={{ fontSize: '15px' }}>
                        {`${el.dia_da_semana} - ${el.horario}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {hibrido_sede_santo_amaro.length > 0 && (
                <>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: '500', 
                        my: 1,
                        color: '#7352c7',
                         
                    }}
                  >
                    Híbrido na {''}
                    <span style={{ textDecoration: 'underline' }}>
                      SEDE SANTO AMARO
                    </span>
                    <span style={{color: '#37373C'}}>
                    {''} (Rua Santo Amaro, 370, Bela Vista):
                    </span>
                  </Typography>

                  <ul>
                    {hibrido_sede_santo_amaro.map((el, index) => (
                      <li key={index} style={{ fontSize: '15px' }}>
                        {`${el.dia_da_semana} - ${el.horario}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {/* Horários virtuais */}
              {virtual.length > 0 && (
                <>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: '500', 
                        my: 1,
                        color: '#7352c7',
                         
                    }}
                  >
                    ON-LINE:

                    <span style={{color: '#37373C'}}>
                      {''} (Virtual pelo Google Meet):
                    </span>
                  </Typography>

                  <ul>
                    {virtual.map((el, index) => (
                      <li key={index} style={{ fontSize: '15px' }}>
                        {`${el.dia_da_semana} - ${el.horario}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Box>
          </Fade>
        </Modal>
      </Card>
    </>
  );
};
/* Todo item prop define */

export default EnrollmentScreenItem;
