import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
/* import ClassStudentsList from "./ClassStudentsList"; */
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import styled from '@emotion/styled';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import EmailEditor from 'app/components/EmailEditor/EmailEditor';
import { convertToRaw, EditorState, ContentState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const FileListItem = styled.li`
  list-style: none;
  /* margin: 1rem 0; */
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.9rem;
`;

const cursos = [
  { id: 1, nome: 'Curso 1' },
  { id: 2, nome: 'Curso 2' },
  { id: 3, nome: 'Curso 3' },
];

const placeholders = [
  { chave: 'nome_do_aluno', descricao: 'Nome do Aluno' },
  { chave: 'nome_do_curso', descricao: 'Nome do Curso' },
  { chave: 'horario', descricao: 'Horário' },
  { chave: 'dia_da_semana', descricao: 'Dia da Semana' },
  { chave: 'modo', descricao: 'Modo' },
];

const MessageForm = () => {
  const { theme } = useJumboTheme();
  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    assunto: '',
    tipo_mensagem: '',
  };

  const validationSchema = yup.object().shape({
    curso_id: yup.string().required('O curso é obrigatório'),
    assunto: yup.string().trim().required('O assunto não pode estar vazio'),
    /* descricao: yup.string().trim().required('A mensagem não pode estar vazia'), */
    tipo_mensagem: yup.string().trim().required('O tipo de mensagem não pode estar vazio'),
  });

  const { id } = useParams();

  const [users, setUsers] = useState(initialValues);

  const [selectedOption, setSelectedOption] = useState(null);

  const [courses, setCourses] = useState([]);

  const [editorMessageState, setEditorMessageState] = useState(
    EditorState.createEmpty()
  );

  const inserirPlaceholder = (chave) => {
    const contentState = editorMessageState.getCurrentContent();
    const selectionState = editorMessageState.getSelection();

    // Insere o texto na posição atual do cursor
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      `{{${chave}}}`
    );

    // Atualiza o estado do editor
    const newEditorState = EditorState.push(
      editorMessageState,
      newContentState,
      'insert-characters'
    );

    // Move o cursor para depois do texto inserido
    setEditorMessageState(
      EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      )
    );
  };

  const { hasPermission } = useContext(PermissionContext);
  let { state } = useLocation();

  const canCreate = hasPermission('Mensagens', 'criar');
  const canEdit = hasPermission('Mensagens', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const getCourseById = useCallback(async (voluntario_id) => {
    console.log(selectedOption);
    console.log(users);
    try {
      ApiService.get(`/mensagens/${id}`)
        .then((response) => {
          setUsers(response.data);
          setSelectedOption(response.data.User);

          const htmlDescription = response.data.descricao || '';
          const blocksFromHtmlDescription = htmlToDraft(htmlDescription);

          if (blocksFromHtmlDescription) {
            const { contentBlocks, entityMap } = blocksFromHtmlDescription;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);

            setEditorMessageState(editorState);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourses = useCallback(async () => {
    try {
      ApiService.get(`/cursos`)
        .then((response) => {
          console.log(response.data);
          setCourses(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getCourseById(selectedOption);
    }
    getCourses();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const htmlDescription = draftToHtml(
      convertToRaw(editorMessageState.getCurrentContent())
    );

    console.log(htmlDescription);
    const allValues = {
      ...values,
      descricao: htmlDescription,
    };
    if (id) {
      await ApiService.put(`/mensagens/${id}`, { values: allValues })
        .then((response) => {
          toast('success', 'Atualizado com sucesso');
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      await ApiService.post('/mensagens', {
        values: allValues,
      })
        .then((response) => {
          toast('success', 'Criado com sucesso');
          setSubmitClicked(true);
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Mensagens'}
          subtitle={id ? 'Editar' : 'Cadastro de Mensagens'}
          titleUrl={'/app/listar-mensagens'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Paper sx={{ p: '40px' }}>
        {state && (
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='large'
            sx={{ maxWidth: { md: '200px' }, mb: '2rem' }}
            onClick={() => navigate(state.backUrl)}
          >
            Voltar
          </Button>
        )}
        <Formik
          initialValues={users}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue, errors }) => {
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                {console.log({ errors })}
                <Grid container spacing={3} alignContent={'center'}>
                  <Grid item xs={6}>
                    <Autocomplete
                      fullWidth
                      id='area_id'
                      options={courses}
                      getOptionLabel={(option) => option.nome || ''}
                      value={
                        courses.find(
                          (area) => area.curso_id === values.curso_id
                        ) || null
                      }
                      onChange={(event, newValue) =>
                        setFieldValue(
                          'curso_id',
                          newValue ? newValue.curso_id : ''
                        )
                      }
                      autoHighlight={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Cursos'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          sx={{ background: '#ffffff' }}
                        />
                      )}
                    />
                    {errors.curso_id && (
                      <FormHelperText
                        style={{ color: theme.palette.error.main }}
                      >
                        {errors.curso_id}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='tipo_mensagem'>
                        Tipo de Mensagem
                      </InputLabel>
                      <JumboSelectField
                        labelId='tipo_mensagem'
                        id='tipo_mensagem'
                        name='tipo_mensagem'
                        value={values.tipo_mensagem}
                        label='Tipo de Mensagem'
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        <MenuItem value={'inscricao_no_curso'}>Inscrição no Curso</MenuItem>
                        {/* <MenuItem value={'aprovado'}>Aprovado</MenuItem>
                        <MenuItem value={'reprovado'}>Reprovado</MenuItem> */}
                        <MenuItem value={'documentacao_invalida'}>Documentação Inválida</MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Variáveis Disponíveis:</Typography>
                    <Box display={'flex'} alignItems={'center'} gap={3}>
                      {placeholders.map((p) => (
                        <Button
                          key={p.chave}
                          variant='outlined'
                          onClick={() => inserirPlaceholder(p.chave)}
                        >
                          {p.descricao}
                        </Button>
                      ))}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <JumboTextField
                      label='Assunto'
                      name='assunto'
                      fullWidth
                      value={values.assunto}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/* Campo de Mensagem */}
                  <Grid item xs={12}>
                    <EmailEditor
                      title={'Mensagem'}
                      editorState={editorMessageState}
                      setEditorState={setEditorMessageState}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
                    <LoadingButton
                      color='success'
                      type='submit'
                      variant='contained'
                      size='large'
                      sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                      loading={isSubmitting}
                      disabled={isDisabled || submitClicked}
                    >
                      {id ? 'Atualizar' : 'Cadastrar'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default MessageForm;
