import { Typography, Tooltip } from "@mui/material";
import { useState, useEffect, useRef} from "react";

export const EllipsisTooltip = ({ text, font = undefined, fontWeight = undefined }) => {
    const [isTextOverflowing, setIsTextOverflowing] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            const { scrollWidth, clientWidth } = textRef.current;
            setIsTextOverflowing(scrollWidth > clientWidth);
        }
    }, [text]);

    return (
        <Tooltip title={isTextOverflowing ? text : ''}>
            <Typography
                variant={'body1'}
                sx={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: font,
                    fontWeight: fontWeight
                }}
                ref={textRef}
            >
                {text}
            </Typography>
        </Tooltip>
    );
};
