import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  Skeleton,
  Paper,
  Modal,
  Fade,
  useMediaQuery,
} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { useJumboTheme } from '@jumbo/hooks';
import { ApiService } from 'app/servicesTwo/ApiService';
import { Link, useNavigate } from 'react-router-dom';
import EnrollmentScreenItem from './EnrollmentScreenItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
};
const EnrollmentScreen = () => {
  const { theme } = useJumboTheme();
  const navigate = useNavigate();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const params = new URLSearchParams(window.location.search);
  const idParam = params.get('id');

  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPrerequisites, setFilterPrerequisites] = useState('all');
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openContinued, setOpenContinued] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenContinued = () => {
    setOpenContinued(true);
  };
  const handleCloseContinued = () => {
    setOpenContinued(false);
  };

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setFirstLoading(false);
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const getClasses = useCallback(async () => {
    try {
      setLoading(true);

      setTimeout(() => {
        const params = {
          filterPrerequisites,
          searchTerm,
          openCourses: true,
          orderByCoursesOrder: true,
          publicCourses: true,
        };

        ApiService.get('/cursos-introdutorios', {
          params: { searchTerm: params },
        })
          .then((response) => {
            console.log(response.data);
            setData(response.data);
            setLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  }, [filterPrerequisites, searchTerm]);

  useEffect(() => {
    getClasses();
  }, [filterPrerequisites, searchTerm, getClasses]);

  const cursosRegulares = data
    .filter((item) => {
      const isTipoRegular = item.Turma[0].Curso.tipo_curso === 'regular';
      const isMatchingId = idParam
        ? item.curso_indrodutorio_id === parseInt(idParam)
        : true;
      return isTipoRegular && isMatchingId;
    })
    .sort((a, b) => {
      
      /* const ordemEspecifica = [
        'INTRODUÇÃO AO ESPIRITISMO',
        'BÁSICO DE ESPIRITISMO 1º - ANO',
        'BÁSICO DE ESPIRITISMO 2º - ANO',
        'APRENDIZES DO EVANGELHO 1º - ANO',
        'APRENDIZES DO EVANGELHO 2º - ANO',
        'EDUCAÇÃO MEDIÚNICA 1º - ANO',
        'EDUCAÇÃO MEDIÚNICA 2º - ANO',
        'ESTUDOS MEDIÚNICOS 1º - ANO',
        'ESTUDOS MEDIÚNICOS 2º - ANO',
        'CDM',
      ];

      const nomeA = a.Turma[0].Curso.ordem_curso.toUpperCase();
      const nomeB = b.Turma[0].Curso.ordem_curso.toUpperCase();

      const indexA = ordemEspecifica.indexOf(nomeA);
      const indexB = ordemEspecifica.indexOf(nomeB);

      // Se ambos os cursos estiverem na ordem específica, ordene pela posição na lista
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // Se nenhum dos cursos estiver na ordem específica, ordene alfabeticamente
      if (indexA === -1 && indexB === -1) {
        return nomeA.localeCompare(nomeB);
      }

      // Se apenas um dos cursos estiver na ordem específica, priorize ele
      return indexA === -1 ? 1 : -1; */

      const cursoA = a.Turma[0].Curso;
      const cursoB = b.Turma[0].Curso;
  
      const ordemA = cursoA.ordem_curso;
      const ordemB = cursoB.ordem_curso;
  
      // Se ambos os cursos tiverem `ordem_curso` nulo, ordene alfabeticamente pelo nome
      if (ordemA === null && ordemB === null) {
        return String(cursoA.nome).localeCompare(String(cursoB.nome));
      }
  
      // Se apenas um dos cursos tiver `ordem_curso` nulo, priorize o outro
      if (ordemA === null) return 1;
      if (ordemB === null) return -1;
  
      // Se `ordem_curso` for numérico, ordene numericamente
      if (!isNaN(ordemA) && !isNaN(ordemB)) {
        return ordemA - ordemB;
      }
  
      // Caso contrário, ordene alfabeticamente por `ordem_curso`
      return String(ordemA).localeCompare(String(ordemB));
    });

  const cursosEducacaoContinuada = data
    .filter((item) => {
      const isTipoEducacaoContinuada =
        item.Turma[0].Curso.tipo_curso === 'educação_continuada';
      const isMatchingId = idParam
        ? item.curso_indrodutorio_id === parseInt(idParam)
        : true;
      return isTipoEducacaoContinuada && isMatchingId;
    })
    .sort((a, b) => {
      const nomeA = a.Turma[0].Curso.nome.toUpperCase(); // Para evitar problemas com case-sensitive
      const nomeB = b.Turma[0].Curso.nome.toUpperCase();
      return nomeA.localeCompare(nomeB); // Ordena alfabeticamente
  });

  console.log('cursosRegulares', cursosRegulares);
  console.log('cursosEducacaoContinuada', cursosEducacaoContinuada);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      <Paper sx={{ p: '2rem' }}>
        <Typography
          variant='h1'
          gutterBottom
          textAlign={'center'}
          fontWeight={600}
        >
          {firstLoading ? (
            <Skeleton width={300} height={40} />
          ) : (
            'Matrículas nos Cursos FEESP 2025'
          )}
        </Typography>

        {/* Filtros */}
        <Box
          display='flex'
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
          marginBottom={3}
          width={'100%'}
        >
          {firstLoading ? (
            <>
              <Skeleton variant='rectangular' width='100%' height={56} />
              <Skeleton variant='rectangular' width='100%' height={56} />
            </>
          ) : (
            <Box
              display='flex'
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={lgDown ? 'column' : 'row'}
              gap={1}
              width={lgDown ? '100%' : '50%'}
            >
              <TextField
                label='Buscar Curso'
                variant='outlined'
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Select
                value={filterPrerequisites}
                onChange={(e) => setFilterPrerequisites(e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem value='all'>Todos os Cursos</MenuItem>
                <MenuItem value='with'>Com Pré-requisitos</MenuItem>
                <MenuItem value='without'>Sem Pré-requisitos</MenuItem>
              </Select>
            </Box>
          )}
        </Box>

        <Grid container spacing={3}>
          {loading ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} key={index}>
                <Skeleton variant='rectangular' width='100%' height={100} />
              </Grid>
            ))
          ) : (
            <>
              {/* Cursos Regulares */}
              {cursosRegulares.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant='h2'
                      sx={{ width: '100%', textAlign: 'center', marginTop: 3 }}
                    >
                      Cursos Regulares
                      <Link onClick={handleOpen}>
                        (Clique aqui para saber mais)
                      </Link>
                    </Typography>
                  </Grid>
                  {cursosRegulares.map((item) => (
                    <Grid item xs={12} key={item.Turma[0].turmas_id}>
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                      >
                        <EnrollmentScreenItem item={item} />
                      </Box>
                    </Grid>
                  ))}
                </>
              )}

              {/* Educação Continuada */}
              {cursosEducacaoContinuada.length > 0 && (
                <>
                  <Typography
                    variant='h2'
                    sx={{ width: '100%', textAlign: 'center', marginTop: 3 }}
                  >
                    Cursos de Educação Continuadas{' '}
                    <Link onClick={handleOpenContinued}>
                      (Clique aqui para saber mais)
                    </Link>
                  </Typography>
                  {cursosEducacaoContinuada.map((item) => (
                    <Grid item xs={12} key={item.Turma[0].turmas_id}>
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                      >
                        <EnrollmentScreenItem item={item} />
                      </Box>
                    </Grid>
                  ))}
                </>
              )}

              {/* Nenhum curso encontrado */}
              {cursosRegulares.length === 0 &&
                cursosEducacaoContinuada.length === 0 && (
                  <Typography
                    variant='body1'
                    color='textSecondary'
                    sx={{ margin: 'auto', textAlign: 'center' }}
                  >
                    Nenhum curso encontrado.
                  </Typography>
                )}
            </>
          )}
        </Grid>
      </Paper>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              p: 4,
              maxWidth: 600,
              margin: 'auto',
              backgroundColor: 'white',
              borderRadius: 2,
              width: '100%',
            }}
          >
            <Typography
              variant='h6'
              id='transition-modal-title'
              sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}
            >
              MATRÍCULA CURSOS FORMAÇÃO BÁSICA EM 2025
            </Typography>
            <Typography variant='body1' sx={{ textAlign: 'justify', mb: 2 }}>
              Os cursos de <strong>formação básica</strong> oferecidos pela
              FEESP são destinados ao Estudo do Espiritismo de forma geral, no
              seu tríplice aspecto – filosófico, científico e religioso –
              visando o progresso espiritual do homem, com fundamento no
              Evangelho de Jesus, à luz da Doutrina Espírita.
            </Typography>

            <Typography variant='body1' sx={{ textAlign: 'justify' }}>
              Para completar todo o <strong>Ciclo Básico</strong> é necessário
              realizar todos os cursos abaixo. Com exceção do curso Introdução
              ao Espiritismo, os demais{' '}
              <strong>requerem que se tenha feito o anterior</strong>. Alunos da
              FEESP, a comprovação é feita automaticamente. Alunos de outras
              instituições precisarão{' '}
              <strong>nos enviar uma declaração oficial</strong> do Centro
              Espírita onde já realizou os cursos correspondentes aos
              pré-requisitos,
              <strong>
                {' '}
                informando a época e a carga horária que o curso foi realizado
              </strong>
              . Esta declaração poderá ser anexada no momento da inscrição ou
              poderá ser enviada para o e-mail{' '}
              <strong style={{ color: '#2EB5C9' }}>
                ensino@feesp.org.br
              </strong>{' '}
              .
            </Typography>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openContinued}
        onClose={handleCloseContinued}
        closeAfterTransition
      >
        <Fade in={openContinued}>
          <Box
            sx={{
              ...style,
              p: 4,
              maxWidth: 600,
              margin: 'auto',
              backgroundColor: 'white',
              borderRadius: 2,
              width: '100%',
            }}
          >
            <Typography
              variant='h6'
              id='transition-modal-title'
              sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}
            >
              MATRÍCULA CURSOS EDUCAÇÃO CONTINUADA EM 2025
            </Typography>
            <Typography
              variant='body1'
              id='transition-modal-description'
              sx={{ textAlign: 'justify', mb: 2 }}
            >
              Os cursos de educação continuada são destinados ao {''}
              <strong>aprofundamento na doutrina espírita</strong>. Tratam de
              assuntos específicos ou estudo de obras clássicas do Espiritismo.
              Vários deles não possuem pré-requisitos. Qualquer um pode se
              inscrever. Alguns outros, para melhor aproveitamento, é necessário
              que o interessado tenha conhecimento prévio, conforme
              pré-requisitos assinalados em cada curso.
            </Typography>
            <Typography variant='body1' sx={{ textAlign: 'justify' }}>
              Neste caso, para alunos da FEESP, a comprovação é feita
              automaticamente. Alunos de outras instituições precisarão
              <strong> nos enviar uma declaração oficial</strong> do Centro
              Espírita onde já realizou os cursos correspondentes aos
              pré-requisitos, {''}
              <strong>
                informando a época e a carga horária que o curso foi realizado
              </strong>
              . Esta declaração poderá ser anexada no momento da inscrição ou
              poderá ser enviada para o e-mail{' '}
              <strong style={{ color: '#2EB5C9' }}>ensino@feesp.org.br</strong>{' '}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </JumboContentLayout>
  );
};

export default EnrollmentScreen;
