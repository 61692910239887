import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Box, Button, Card, CardContent, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Radio, RadioGroup, Select, Stack,Switch,Tooltip,Typography,Zoom,useMediaQuery} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import JumboSearch from "@jumbo/components/JumboSearch";
/* import ClassStudentsList from "./ClassStudentsList"; */
import ListRow from "../../../components/ListRow";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import {useDebouncedCallback} from "beautiful-react-hooks";
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses/JumboSearchClasses';
import ListRowCoursesIntro from 'app/components/CoursesIntro/ListRowCoursesIntro';

import makeAnimated from 'react-select/animated';
import TextEditor from 'app/components/TextEditor/TextEditor';
import {convertToRaw, EditorState, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import CoursesIntroRegistrationList from './CoursesIntroRegistrationsList';
import ListRowCourseIntro from 'app/components/ListRowCourseIntro/ListRowCourseIntro';
import MessageSelector from 'app/components/MessageSelector/MessageSelector';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

export const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(Item)`
    padding: 4px 10px;
    background: #0092D0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 0.5rem;
    cursor: pointer;

    svg {
        color: white;
    }
`



const CoursesIntroForm = () => {

    const labels = {
    inscricao_no_curso: 'Inscrição no Curso',
    /*   aprovado: "Aprovado",
    reprovado: "Reprovado", */
    documentacao_invalida: "Documentação Inválida",
    };
    
    const [messageSelected, setMessageSelected] = useState({
        inscricao_no_curso: { id: '', descricao: '' },
       /*  aprovado: { id: '', descricao: '' },
        reprovado: { id: '', descricao: '' }, */
        documentacao_invalida: { id: '', descricao: '' },
    });
    

    const handleMessageChange = (tipo, mensagem) => {
        setMessageSelected((prev) => ({
            ...prev,
            [tipo]: {
                id: mensagem ? mensagem.mensagem_id : '',
                descricao: mensagem ? mensagem.descricao : ''
            }
        }));
    };

    console.log('messageSelected', messageSelected)

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const navigate = useNavigate();
    let { state, pathname } = useLocation();
    const animatedComponents = makeAnimated();

    const Swal = useSwalWrapper();
    const toast = (variant, message, timer = undefined) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: timer,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });

        //  Redireciona se mensagem de retorno for de sucesso
        /* if(id) {
            if(variant == 'success'){
                setTimeout(function() {
                    navigate("/app/listar-introdutorios-cursos")

                }, 2000)
            }
        } */
    };

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        curso_id: "",
        ano: "",
    }

    const validationSchema = yup.object().shape({
        curso_id: yup
            .string()
            .required('Preenchimento obrigatório'),
    }, []);

    const { id } = useParams()
    const [courseIntro, setCourseIntro] = useState([])
    const [courses, setCourses] = useState([]);
    const [classes, setClasses] = useState(initialValues);
    const [searchTerm, setSearchTerm] = useState(null)
    const [loading, setLoading] = useState(false)
    const [coursesInClasses, setCoursesInClasses] = useState([])
    const [infosAboutCourse, setInfosAboutCourse] = useState([])
    const [classesSelected, setClassesSelected] = useState([]);
    const [isVerifiedCourse, setIsVerifiedCourse] = useState(false);
    const [editorTitleState, setEditorTitleState] = useState(EditorState.createEmpty());
    const [editorSubtitleState, setEditorSubtitleState] = useState(EditorState.createEmpty());
    const [editorDescriptionState, setEditorDescriptionState] = useState(EditorState.createEmpty());
    const [editorComplementState, setEditorComplementState] = useState(EditorState.createEmpty());
    const [infoText, setInfoText] = useState({});
    const [isClassesOpen, setIsClassesOpen] = useState(false)


    const [addStudents, setAddStudents] = useState(null);
    const [courseIntroStudents, setCourseIntroStudents] = useState([]);
    const [usersRegistration, setUsersRegistration] = useState([]);

    const [isInputFocused, setIsInputFocused] = useState(false);

    const { hasPermission } = useContext(PermissionContext);

    const canCreate = hasPermission('Turmas', 'criar');
    const canEdit = hasPermission('Turmas', 'editar');
    const isDisabled = id ? !canEdit : !canCreate

    useEffect(() => {
        if(searchTerm == undefined || searchTerm == ''){
            setAddStudents(null);
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
          searchStudent(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const searchStudent = useCallback(async (e) => {
        try {
            ApiService.get(`/pessoa`, {params: {search: e}})
            .then((response) => {
                setAddStudents(response.data)
                // toast('success', 'Aluno adicionado com sucesso');
            })
            .catch((error) => {
                // toast('error', 'Ocorreu um erro');

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getCourse = useCallback(async () => {
        try {
            ApiService.get(`/cursos`)
            .then((response) => {
              setCourses(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const updateStudentList = useCallback(async (data) => {
        setUsersRegistration([...data]);
        setAddStudents(null);
    }, []);

    const getCourseClasses = useCallback(async (infosAboutCourse, courseId, ano) => {
        if (!infosAboutCourse || !courseId) {
            console.error("Dados insuficientes para buscar classes.");
            return;
        }
    
        const turmasIds = infosAboutCourse.map((turma) => turma.turmas_id);
        try {
            const response = await ApiService.get(`/turmas/cursos`, {
                params: { turmasIds, courseId, ano },
            });

            console.log(response.data);

            if (response.data.length > 0) {
                const data = response.data
                setInfosAboutCourse((prevState) => [
                    ...prevState, // Manter os dados existentes
                    ...data       // Adicionar os novos dados
                ]);
                setIsVerifiedCourse(true);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);
    
    const getCourseById = useCallback(async () => {
        try {
            ApiService.get(`/cursos-introdutorios/${id}`, {
                params: {
                    orderByWeekday: true,
                }
            })
            .then((response) => {
                console.log(response.data);
                setCourseIntro(response.data)
                setClasses(response.data.Turma[0].Curso);
                setCoursesInClasses(response.data.coursesInClasses);
                setIsVerifiedCourse(true)
                setInfosAboutCourse(response.data.Turma)
                setClassesSelected(response.data.Turma)
                setIsClassesOpen(response.data.aberto)
               /*  setUsersRegistration(response.data.Curso_Introdutorio_Historico) */
               const students = (response.data.Curso_Introdutorio_Historico).map((x) => (
                    {
                        ...x.User, 
                        Turma: x.Turma, 
                        turmas_id: x.Turma.turmas_id,
                        curso_introdutorio_historico_id: x.curso_introdutorio_historico_id,
                        status_curso_introdutorio_historico: x.status,
                        ficha_compativel: x.ficha_compativel,
                        observacao: x.observacao,
                    }
                    ));
                setUsersRegistration(students);

                const messages = response.data.Curso_Introdutorio_Mensagens.reduce((acc, item) => {
                    if (item.Mensagem) {
                        acc[item.Mensagem.tipo_mensagem] = {
                            id: item.Mensagem.mensagem_id,
                            descricao: item.Mensagem.descricao
                        };
                    }
                    return acc;
                }, {});

                console.log('dsdsdfdfd',messages)

    
                setMessageSelected((prev) => ({
                    ...prev,
                    ...messages
                }));

                console.log('setMessageSelected', messageSelected)

                const htmlTitle = response.data.titulo;
                const blocksFromHtmlTitle = htmlToDraft(htmlTitle);
                const { contentBlocks: contentBlocksTitle, entityMap: entityMapTitle } = blocksFromHtmlTitle;

                const htmlSubtitle = response.data.subtitulo;
                const blocksFromHtmlSubtitle = htmlToDraft(htmlSubtitle);
                const { contentBlocks: contentBlocksSubtitle, entityMap: entityMapSubtitle } = blocksFromHtmlSubtitle;

                const htmlDescription = response.data.descricao;
                const blocksFromHtmlDescription = htmlToDraft(htmlDescription);
                const { contentBlocks: contentBlocksDescription, entityMap: entityMapDescription } = blocksFromHtmlDescription;

                const htmlComplement = response.data.texto_complementar;
                const blocksFromHtmlComplement = htmlToDraft(htmlComplement);
                const { contentBlocks: contentBlocksComplement, entityMap: entityMapComplement } = blocksFromHtmlComplement;


                const contentStateTitle = ContentState.createFromBlockArray(contentBlocksTitle, entityMapTitle);
                const editorStateTitle = EditorState.createWithContent(contentStateTitle);

                const contentStateSubtitle = ContentState.createFromBlockArray(contentBlocksSubtitle, entityMapSubtitle);
                const editorStateSubtitle = EditorState.createWithContent(contentStateSubtitle);

                const contentStateDescription = ContentState.createFromBlockArray(contentBlocksDescription, entityMapDescription);
                const editorStateDescription = EditorState.createWithContent(contentStateDescription);

                const contentStateComplement = ContentState.createFromBlockArray(contentBlocksComplement, entityMapComplement);
                
                const editorStateComplement = EditorState.createWithContent(contentStateComplement);

                setEditorTitleState(editorStateTitle);
                setEditorSubtitleState(editorStateSubtitle);
                setEditorDescriptionState(editorStateDescription);
                setEditorComplementState(editorStateComplement);

                if (response.data.Turma?.length > 0 && response.data.Turma[0].Curso?.curso_id) {
                    getCourseClasses(response.data.Turma, response.data.Turma[0].Curso.curso_id, 2025);
                }
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, [id, getCourseClasses]);

   
console.log(infosAboutCourse)

    useEffect(() => {
        if(id) getCourseById()
        getCourse();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [pathname]);
   
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        console.log(infosAboutCourse)
        if(infosAboutCourse.length === 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            //setSubmitting(false)
            return toast('warning', 'Não há turmas')
        }

        //const finalObject = { turmasIds: turmasIds };
        const turmasIds = infosAboutCourse.map(((turma) => ({ turmas_id: turma.turmas_id })))
    
        const htmlTitle = draftToHtml(convertToRaw(editorTitleState.getCurrentContent()));
        const htmlSubtitle = draftToHtml(convertToRaw(editorSubtitleState.getCurrentContent()));
        const htmlDescription = draftToHtml(convertToRaw(editorDescriptionState.getCurrentContent()));
        const htmlComplement = draftToHtml(convertToRaw(editorComplementState.getCurrentContent()));
        const allValues = {...values, turmasIds, htmlTitle, htmlSubtitle, htmlDescription, htmlComplement, isClassesOpen, messageSelected}
        
        if(id) {
            await ApiService.put(`/cursos-introdutorios/${id}`, { values: allValues })
            .then((response) => {
            toast('success', 'Atualizado com sucesso')
            })
            .catch((error) => {
            toast('error', 'Ocorreu um erro')
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })
        } else {
            await ApiService.post('/cursos-introdutorios', { values: allValues })
            .then((response) => {
                toast('success', 'Criado com sucesso', 10000)
                resetForm()
                setEditorTitleState(EditorState.createEmpty())
                setEditorSubtitleState(EditorState.createEmpty())
                setEditorDescriptionState(EditorState.createEmpty())
                setEditorComplementState(EditorState.createEmpty())
                setClassesSelected([])
                setInfosAboutCourse([])
                setIsVerifiedCourse(false)
            })
            .catch((error) => {
                toast('error', error.response?.data?.error || 'Ocorreu um erro')
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })
        }


        setSubmitting(false);
    } 

    const handleInputFocus = () => {
        setIsInputFocused(true);
      }
    
    const handleInputBlur = () => {
        setIsInputFocused(false);
    }

    const removeHTMLTags = (str) => {
        return str?.replace(/<[^>]*>?/gm, '');
    }

    const data = [];

    if(courseIntro?.Turma?.length > 0 ) {
        for (const item of courseIntro?.Turma ) {
    
            for(const el of item?.Curso_Historico) {

                const nomeCurso = item?.Curso?.nome
                const cleanClasses = {
    
                    nome_curso_introdutorio: nomeCurso,
                    titulo_curso_introdutorio: removeHTMLTags(courseIntro.titulo),
    
                    horario_escolhido: item?.horario,
                    dia_da_semana_escolhido: item?.dia_da_semana,
                    local: item?.local,
    
                    nome_do_inscrito: el?.User.nome,
                    cpf: el?.User.cpf,
                    email: el?.User.email,
                    data_nascimento: el?.User.data_nascimento,
                    genero: el?.User.genero,
                    nacionalidade: el?.User.nacionalidade,
                    rg: el?.User.rg,
                    telefone: el?.User.telefone,
                    celular: el?.User.celular,
                  
                   /*  aceita_contato_whatsapp: item.aceita_contato_whatsapp,
                    documento_internacional: item.documento_internacional,
                    estado_civil: item.estado_civil,
                    tem_filhos: item.tem_filhos,
                    qtde_filhos: item.qtde_filhos,
                    quais_cuidados: item.quais_cuidados,
                    escolaridade: item.escolaridade,
                    formacao_principal: item.formacao_principal,
                    ocupacao: item.ocupacao,
                    ocupacao_natureza: item.ocupacao_natureza ,
                    profissao: item.profissao,
                    habilidade_especifica: item.habilidade_especifica,
                    religiao: item.religiao,
                    conhece_espiristimo: item.conhece_espiristimo,
                    cursos_fora_feesp: item.cursos_fora_feesp,
                    obs_voluntariado: item.obs_voluntariado,
                    obs_gerais: item.obs_gerais,
                    bairro: item.bairro,
                    cidade: item.cidade,
                    estado: item.estado,
                    CEP: item.CEP,
                    codigo_postal: item.codigo_postal,
                    complemento: item.complemento,
                    endereco: item.endereco,
                    numero: item.numer,
                    pais: item.pais,
                    nome_emergencia: item.nome_emergencia ,
                    celular_emergencia: item.celular_emergencia, */
                }
                data.push(cleanClasses);
            }
        }
    }

    const generateOptions = (key) => ({ label: key, key });

    const headers = [
        //para curso introdutorio
        generateOptions('nome_curso_introdutorio'),
        generateOptions('titulo_curso_introdutorio'),
        // para area e depart
        generateOptions('horario_escolhido'),
        generateOptions('dia_da_semana_escolhido'),
        generateOptions('local'),
        
        // para alunos
        generateOptions('nome_do_inscrito'),
        generateOptions('cpf'),
        generateOptions('email'),
        generateOptions('data_nascimento'),
        generateOptions('genero'),
        generateOptions('nacionalidade'),
        generateOptions('rg'),
        generateOptions('telefone'),
        generateOptions('celular'),
       


        /* generateOptions('aceita_contato_whatsapp'),
        generateOptions('documento_internacional'),
        generateOptions('estado_civil'),
        generateOptions('tem_filhos'),
        generateOptions('qtde_filhos'),
        generateOptions('quais_cuidados'),
        generateOptions('escolaridade'),
        generateOptions('formacao_principal'),
        generateOptions('ocupacao'),
        generateOptions('ocupacao_natureza'),
        generateOptions('profissao'),
        generateOptions('habilidade_especifica'),
        generateOptions('religiao'),
        generateOptions('conhece_espiristimo'),
        generateOptions('cursos_fora_feesp'),
        generateOptions('obs_voluntariado'),
        generateOptions('obs_gerais'),
        generateOptions('bairro'),
        generateOptions('cidade'),
        generateOptions('estado'),
        generateOptions('CEP'),
        generateOptions('codigo_postal'),
        generateOptions('complemento'),
        generateOptions('endereco'),
        generateOptions('numero'),
        generateOptions('pais'),
        generateOptions('nome_emergencia'),
        generateOptions('celular_emergencia'), */


       
        /* generateOptions('nome_do_curso'),
        generateOptions('dia_da_semana'),
        generateOptions('horario'),
        generateOptions('local'),
        generateOptions('status'),
        generateOptions('motivo_reprovacao'), */
    ];

    const headerLabels = headers.map(header => header.label);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(data, { header: headerLabels });
        const wb = { Sheets: { 'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const nData = new Blob([excelBuffer], { type: fileType})
        FileSaver.saveAs(nData, 'inscritos_curso_introdutorio' + fileExtension)
    }

    const handleChangeSearch = useDebouncedCallback((event) => {
        setSearchTerm(event.target.value);
    });

    
    React.useEffect(() => {
        setSearchTerm(searchTerm);
    }, [searchTerm]);

    React.useEffect(() => {
        return () => handleChangeSearch.cancel();
    });

   

   
    const verifyIfCourseHasClasses = useCallback(async (curso_id, ano, setErrors) => {

        try {
            await ApiService.get(`/cursos/${curso_id}`, {
                params: { ano }
            })
            .then((response) => {
                console.log(response.data)
                if(response.data.Turma.length > 0) {
                    setInfosAboutCourse(response.data.Turma)
                    setIsVerifiedCourse(true)
                }else {
                    setInfosAboutCourse(response.data.Turma)
                    setIsVerifiedCourse(false)
                    setInfoText({
                        message: 'Não há turma cadastrada. Acessar no menu a opção ',
                        bold: 'Turmas',
                        suffix: ' para criá-las.',
                    });
                    toast('warning', 'Não há turma cadastrada. Acessar no menu a opção Turmas para criá-las')
                }
                if(!id && classesSelected.length > 0) {
                    setClassesSelected([])
                }
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const handleSelectTurma = (turma) => {
        setClassesSelected(prevTurmas => {
          // Verifica se a turma já está selecionada
          if (prevTurmas.find(t => t.turmas_id === turma.turmas_id)) {
            // Se estiver, remova-a da lista de turmas selecionadas
            return prevTurmas.filter(t => t.turmas_id !== turma.turmas_id);
          } else {
            // Se não estiver, adicione-a à lista de turmas selecionadas
            return [...prevTurmas, turma];
          }
        });
    }

  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Setup de Curso no Ano"}
                subtitle={id ? "Editar Setup de Novo Curso" : "Setup de Novo Curso"}
                titleUrl={"/app/listar-inscricoes-abertas"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        {
            id && (
                <Box display='flex' justifyContent='flex-end' mb={1}>
                    <Tooltip
                        title="Exportar dados"
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <CSVStyled onClick={(e) => exportToExcel()}>
                            <CSVStyled data={data} headers={headers} filename={'meu-arquivo.csv'}>
                            <Typography
                                variant={"h5"}
                                color="#ffffff"
                                mb={.25}
                            >
                                Download
                            </Typography>
                            <DownloadIcon/>
                            </CSVStyled>
                        </CSVStyled>
                    </Tooltip>
                </Box>
            )

        }
        <Paper sx={{p: '40px'}}>
            {state 
                ?
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px', mt: '1rem'}, mb: '2rem'}}
                    onClick={() => navigate(state.backUrl)}
                >
                    Voltar
                </Button>
                :
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px', mt: '1rem'}, mb: '2rem'}}
                    onClick={() => navigate('/app/listar-inscricoes-abertas')}
                >
                    Voltar
                </Button>
            }
            <Formik
                initialValues={classes}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
            >
                {({values, isSubmitting, handleChange, setErrors}) => (
                    <Form style={{width: '100%'}} noValidate autoComplete='off'>
                        <Grid container spacing={3} alignContent={'center'}>
                            {
                                !id
                                ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant='h5' fontWeight={'bold'}>
                                                Escolha o curso e o ano para verificar as turmas disponíveis.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7} >
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="curso_id">Curso</InputLabel>
                                                <JumboSelectField
                                                    labelId="curso_id"
                                                    id="curso_id"
                                                    name="curso_id"
                                                    value={updateInputValue(classes, values, 'curso_id')}
                                                    label="Curso"
                                                    disabled={isDisabled}
                                                    notched
                                                >
                                                    {courses?.length > 0 && courses.map((x) => 
                                                        <MenuItem value={x.curso_id}>{x.nome}</MenuItem>
                                                    )}
                                                </JumboSelectField>

                                            </FormControl>                               
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="ano">Ano</InputLabel>
                                                <JumboSelectField
                                                    labelId="ano"
                                                    id="ano"
                                                    name="ano"
                                                    value={updateInputValue(classes, values, 'ano')}
                                                    label="Ano"
                                                    disabled={isDisabled}
                                                    notched
                                                    
                                                >
                                                    
                                                    <MenuItem 
                                                        value={new Date().getFullYear()}
                                                    >
                                                        {new Date().getFullYear()}
                                                    </MenuItem>
                                                    <MenuItem 
                                                        value={new Date().getFullYear() + 1}
                                                    >
                                                        {new Date().getFullYear() + 1}
                                                    </MenuItem>
                                                </JumboSelectField>

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <LoadingButton
                                                color="success"
                                                variant="contained"
                                                size="large"
                                                onClick={() => verifyIfCourseHasClasses(values.curso_id, values.ano, setErrors)}
                                                sx={{maxWidth: { mt: '1rem'}}}
                                                disabled={!hasPermission('Turmas', 'criar') || !values.curso_id || !values.ano}
                                            >
                                                Verificar Turmas
                                            </LoadingButton>   
                                        </Grid>
                                    </>
                                :  
                                <Grid item xs={12}>
                                    <Typography textAlign="center" variant="h5" fontWeight="bold">
                                        {courseIntro?.Turma?.[0] && (
                                            <>
                                                {courseIntro.Turma[0].Curso?.nome} - {courseIntro.Turma[0].ano}
                                            </>
                                        )}
                                    </Typography>

                                </Grid>
                            }

                            {loading 
                                && 
                                <Box display='flex' justifyContent='center' width={'100%'}>
                                    <CircularProgress  /> 
                                </Box>
                            }
                            {
                                (isVerifiedCourse) &&
                                (<>
                                    {/* <Grid item xs={12}>
                                        <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group">Selecionar se a matricula está aberta</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={isClassesOpen}
                                                onChange={(e) => setIsClassesOpen(e.target.value)}
                                            >
                                                <FormControlLabel value={true} control={<Radio />} label='Sim' />
                                                <FormControlLabel value={false} control={<Radio />} label="Não" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid> */}
                                    <Box 
                                        display='flex' 
                                        justifyContent='center' 
                                        flexDirection='column' 
                                        width='100%'
                                        padding='0 25px'
                                        marginTop='1rem'
                                        gap= '1rem'
                                    >
                                        {
                                            !id 
                                            ?
                                                <Typography>
                                                    Turmas cadastradas para o curso.
                                                </Typography>
                                            :
                                            undefined
                                        }
                                        {
                                            (infosAboutCourse != null && infosAboutCourse.length > 0) ?                
                                            <ListRowCoursesIntro 
                                                data={infosAboutCourse} 
                                                classesSelected={classesSelected}
                                                setClassesSelected={setClassesSelected}
                                                handleSelectTurma={handleSelectTurma}
                                            />
                                            :
                                            null
                                        }
                                    </Box>
                                    {
                                        infosAboutCourse?.length > 0 && 
                                        infosAboutCourse[0]?.Curso?.Mensagens?.length > 0 
                                        ?
                                           
                                           <>
                                                <Grid item xs={12}>
                                                    <Typography variant='h4' gutterBottom>
                                                        Selecione a mensagem que será enviada ao aluno:
                                                    </Typography>
                                                    <Typography variant='h5' color='textSecondary' paragraph>
                                                        Escolha uma mensagem para cada categoria (Inscrição no Curso e Documentação Inválida). Essa mensagem será incluída no e-mail enviado ao aluno.
                                                    </Typography>
                                                </Grid>
                                                <MessageSelector
                                                    courseSelected={infosAboutCourse[0]?.Curso?.nome}
                                                    messages={infosAboutCourse[0]?.Curso?.Mensagens}
                                                    messageSelected={messageSelected}
                                                    handleMessageChange={handleMessageChange}
                                                />
                                           </>
                                            
                                        :
                                        undefined
                                    }
                                    {
                                        messageSelected && (
                                            <Grid item xs={12}>
                                                <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                                                    <Box flexGrow={1}>
                                                        {Object.entries(messageSelected).map(([tipo, { descricao }]) => (
                                                            descricao ? (
                                                                <Box key={tipo} mb={2}>
                                                                    <Typography variant="h5" fontWeight="bold">
                                                                        {labels[tipo] || tipo.replace('_', ' ')} {/* Nome legível */}
                                                                    </Typography>
                                                                    <div dangerouslySetInnerHTML={{ __html: descricao }} />
                                                                </Box>
                                                            ) : null
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={12}>
                                        <TextEditor
                                            title={'Título'}
                                            editorState={editorTitleState}
                                            setEditorState={setEditorTitleState}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextEditor
                                            title={'Subtítulo'}
                                            editorState={editorSubtitleState}
                                            setEditorState={setEditorSubtitleState}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextEditor
                                            title={'Descrição'}
                                            editorState={editorDescriptionState}
                                            setEditorState={setEditorDescriptionState}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextEditor
                                            title={'Complementar'}
                                            editorState={editorComplementState}
                                            setEditorState={setEditorComplementState}
                                        />
                                    </Grid>
                                </>)
                            }
                            <Grid item xs={12}>
                                {
                                    (infosAboutCourse != null && infosAboutCourse.length > 0)
                                        ? 
                                            null
                                        :
                                        
                                    <Typography>
                                        {infoText.message}
                                        <strong>{infoText.bold}</strong>
                                        {infoText.suffix}
                                    </Typography>
                                }
                            </Grid>
                            

                            {!id && isVerifiedCourse ? 
                            (
                                <Grid item xs={12} sx={{textAlign: 'center', /* display:'none' */}}> 
                                    <LoadingButton
                                        color="success"
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                        loading={isSubmitting}
                                        disabled={!hasPermission('Turmas', 'criar')}
                                    >
                                        Salvar
                                    </LoadingButton>
                                </Grid>
                            )
                            :
                                null
                            }
                            {
                                id 
                                ?
                                (
                                    <>
                                        <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                            <LoadingButton
                                                color="success"
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                                loading={isSubmitting}
                                                disabled={isDisabled || isInputFocused}
                                            >
                                                Atualizar
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item xs={12}>
    
                                           {/*  <Typography variant={"h4"} mb={1}>Adicionar inscrito</Typography>
    
                                            <Grid item xs={1} sx={{textAlign: 'center'}}> 
                                                <JumboSearchClasses
                                                    value={searchTerm}
                                                    onChange={(e) =>  setSearchTerm(e.target.value)}
                                                    placeholder="Busque por Nome, CPF ou E-mail."
                                                    sx={{
                                                        width: '440px',
                                                        marginBottom: '1rem'
                                                    }}
                                                    onFocus={handleInputFocus} 
                                                    onBlur={handleInputBlur}
                                                />
                                            </Grid> */}
    
                                            {/* {
                                                (addStudents != null && addStudents.length > 0) && 
                                                <ListRowCourseIntro 
                                                    setUsersRegistration={setUsersRegistration} 
                                                    usersRegistration={usersRegistration} 
                                                    data={addStudents}
                                                    infosAboutCourse={infosAboutCourse} 
                                                    setInfosAboutCourse={setInfosAboutCourse}
                                                    updateStudentList={(data) => updateStudentList(data)} courseIntroStudents={courseIntroStudents} 
                                                    setSearchTerm={setSearchTerm}
                                                    getCourseById={getCourseById}
                                                />
                                            }
                                            {
                                                (addStudents != null && addStudents.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                                            } */}
    
                                            {/* <Typography variant={"h4"} mb={1} sx={{ marginTop: '2rem' }}>Lista de inscritos</Typography>
    
                                            { (usersRegistration.length > 0 && usersRegistration[0].pessoa_id == null)
                                                ? <Typography>Sem resultados correspondentes</Typography>
                                                : 
                                                
                                                <CoursesIntroRegistrationList
                                                    setUsersRegistration={setUsersRegistration} 
                                                    usersRegistration={usersRegistration} 
                                                    handleInputFocus={handleInputFocus}
                                                    handleInputBlur={handleInputBlur}
                                                />
                                            } */}
    
                                        </Grid>
                                    </>
    
                                )
                                :
                                null
                            }
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default CoursesIntroForm
