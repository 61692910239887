import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import * as yup from 'yup';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useLocation } from 'react-router-dom';
import Div from '@jumbo/shared/Div';
import JumboSearch from '@jumbo/components/JumboSearch';
import { PermissionContext } from 'app/contexts/PermissionContext';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import UserCertifiedItem from './UserCertifiedItem';
import JumboSearchWithoutDebounce from "@jumbo/components/JumboSearchWithoutDebounce";


const UserCertifiedList = () => {
    const { theme } = useJumboTheme();
    const location = useLocation();
    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto',
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none',
                    },
                },
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start',
                },
            },
        }),
        [theme]
    );

    const [users, setUsers] = useState(null);
    const { hasPermission } = useContext(PermissionContext);

    const [searchTerm, setSearchTerm] = useState(null);
    const [loading, setLoading] = useState(false);


    const searchUser = useCallback(async (e) => {
        try {
            ApiService.get('/pessoa', { params: { search: e } })
                .then((response) => {
                    setUsers(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
    
            setTimeout(() => {
              searchUser(searchTerm);
            }, 1000)
        }
    };

    return (
        <JumboContentLayout
            header={<PageHeader title={'Certificados'} />}
            layoutOptions={layoutOptions}
        >
            {lg && (
                <Stack
                    spacing={2}
                    direction={'row'}
                    sx={{ mb: 3, mt: -2 }}
                ></Stack>
            )}
            <Paper sx={{ p: '40px' }}>

            <Typography 
                variant="h6" 
                sx={{fontWeight: '500', marginBottom: '1rem', marginLeft: '1rem'}}
            >
                Busque por nome, email, celular e cpf.
            </Typography>

            <Box
                display='flex'
                alignItems='center'
                gap='1rem'
                marginBottom='1rem'
                flexWrap='wrap'
                onKeyDown={(e) => {
                    handleKeyDown(e, searchTerm)
                }}
            >
                <Box display='flex' alignItems='center'>
                    <JumboSearchWithoutDebounce
                        defaultValue={searchTerm}
                        onChange={(e) => setSearchTerm(e)}
                        placeholder='Digite o nome, email, celular ou cpf aqui...'
                        sx={{
                            width: '440px',
                        }}
                    /> 
                </Box>

                <Box display='flex' alignItems='center' gap='1rem'>
                    <Button variant={'contained'} onClick={() => searchUser(searchTerm)}>
                        Filtrar
                    </Button>
                </Box>
            </Box>

                <>
                    {loading && (
                        <Box display='flex' justifyContent='center'>
                            <CircularProgress />
                        </Box>
                    )}
                    {users != null && users.length == 0 && (
                        <Typography>Sem resultados correspondentes</Typography>
                    )}

                    {users &&
                        users.map((user, key) => (
                            <UserCertifiedItem
                                item={user}
                                setUsers={(data) => setUsers([...data])}
                                users={users}
                                key={key}
                            />
                        ))}
                </>
            </Paper>
        </JumboContentLayout>
    );
};

export default UserCertifiedList;
