import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Box, Button, CircularProgress, Grid, Paper, Stack, Tooltip, Typography, useMediaQuery, Zoom} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { ApiService } from 'app/servicesTwo/ApiService';
import { Link, useLocation } from "react-router-dom";
import Div from '@jumbo/shared/Div';
import JumboSearch from '@jumbo/components/JumboSearch';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVStyled, Item } from '../classes/ClassItem';
import AssociateItem from './AssociateItem';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboSearchWithoutDebounce from "@jumbo/components/JumboSearchWithoutDebounce";


const VolunteerList = () => {  
    const {theme} = useJumboTheme();
    const location = useLocation();
    const Swal = useSwalWrapper();
    
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const [users, setUsers] = useState(null)
    const [select1Value, setSelect1Value] = useState(null);
    const [select2Value, setSelect2Value] = useState(null);
    const [select3Value, setSelect3Value] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const animatedComponents = makeAnimated();

    const { hasPermission } = useContext(PermissionContext);

    const handleSelect1Change = (selected) => {
        setSelect1Value(selected.length == 0 ? null : selected);
    };

    const handleSelect2Change = (selected) => {
    setSelect2Value(selected.length == 0 ? null : selected);
    };


    const optionsStatus = ['ativo', 'inativo'].map((result) => {
        return {
            value: result,
            label: result
        }
    })

    const optionsAssociateTypes = ['Associado Contribuinte', 'Associado Efetivo'].map((result) => {
        return {
            value: result,
            label: result
        }
    })
    
    const searchData = {
        select1Value,
        select2Value,
    };

    const getUsers = useCallback(async (searchData) => {
        try {
            ApiService.get('/associados', {params: {searchData}})
            .then((response) => {
                console.log(response.data)
              setUsers(response.data);
              setIsLoading(false)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      function handleSubmit() {
        // if (!select1Value?.length && !select2Value?.length && !select3Value?.length && !startDate && !endDate) {
        //   return;
        // }
  
        const searchData = {
          select1Value,
          select2Value,
        };
  
        console.log('searchData', searchData)
  
        getUsers(searchData);
      }

    useEffect(() => {
    getUsers(searchData);
    }, []);

    useEffect(() => {
    console.log('atualizou')
    }, [setUsers, users]);

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    const [searchTerm, setSearchTerm] = useState(null)
    const [loading, setLoading] = useState(false)

    const searchUser = useCallback(async (e) => {
        console.log('pesquisar', e)
        try {
            ApiService.get('/associados', {params: {search: e}})
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })
        } catch (err) {
            console.log(err);
        }
    }, []);

    const small = window.matchMedia('(max-width:1330px)');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            setTimeout(() => {
                searchUser(searchTerm);
            }, 1000)
        }
    };

  return (
    <JumboContentLayout
        header={
            <PageHeader
                title={"Associados"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            <Grid item xs={1} sx={{textAlign: 'center'}}> 
                <Link to="/app/novo-associado" style={{ textDecoration:'none', pointerEvents: !hasPermission('Associados', 'criar') ? 'none' : ''  }} state={{ backUrl: location.pathname }}>
                    <LoadingButton
                        color="success"
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={!hasPermission('Associados', 'criar') ? true : false}
                        sx={{maxWidth: {md: '200px', mt: '1rem'}, marginBottom: '2rem'}}
                    >
                        Novo Cadastro
                    </LoadingButton>
                </Link>
            </Grid>
            <Grid container spacing={3} justifyContent={'center'} alignContent={'center'} sx={{mb: '2rem', justifyContent: 'center'}}>
                <Grid item xs={4}>
                    <Select 
                        id="select-one"
                        isMulti 
                        options={optionsStatus}
                        components={animatedComponents}
                        value={select1Value}
                        onChange={handleSelect1Change}
                        placeholder="Selecione status"
                        styles={{
                            menu: (list) => ({
                              ...list,
                              zIndex: 3,
                            }),
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        id="select-two"
                        isMulti 
                        options={optionsAssociateTypes}
                        components={animatedComponents}
                        value={select2Value}
                        onChange={handleSelect2Change}
                        placeholder="Selecione tipo associado"
                    />
                </Grid>
                <Grid item xs={small.matches ? 2 : 3}>
                    <Button 
                    fullWidth 
                    variant={'contained'} 
                    onClick={handleSubmit}
                    >
                        Filtrar
                    </Button>
                </Grid>
            </Grid>

            <Typography 
                variant="h6" 
                sx={{fontWeight: '500', marginBottom: '1rem', marginLeft: '1rem'}}
            >
                Busque por nome, email, celular e cpf.
            </Typography>

            <Box
                display='flex'
                alignItems='center'
                gap='1rem'
                marginBottom='1rem'
                flexWrap='wrap'
                onKeyDown={(e) => {
                    handleKeyDown(e, searchTerm)
                }}
            >
                <Box display='flex' alignItems='center'>
                    <JumboSearchWithoutDebounce
                        defaultValue={searchTerm}
                        onChange={(e) => setSearchTerm(e)}
                        placeholder='Digite o nome, email, celular ou cpf aqui...'
                        sx={{
                            width: '440px',
                        }}
                    /> 
                </Box>

                <Box display='flex' alignItems='center' gap='1rem'>
                    <Button variant={'contained'} onClick={() => searchUser(searchTerm)}>
                        Filtrar
                    </Button>
                </Box>
            </Box>


            <>
                {
                    (users != null && users.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                }

                {
                    users && users.map((user, key) => (
                        <AssociateItem item={user} setUsers={(data) => setUsers([...data])} users={users} key={key}/>
                    ))
                }

                {isLoading && <CircularProgress/>}
            </>
        </Paper>
    </JumboContentLayout>
  )
}

export default VolunteerList