import React from 'react'
import styled from '@emotion/styled'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';

const FileListItem = styled.li`
    list-style: none;
    margin: 1rem 0;
    background-color: #f55e3038;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.9rem;
    width: 100%;
`

const FileItemSlim = ({ file, removeFile, index, isEditingFile, textFile, handleInputChangeFile, handleSaveClick, handleEditClick, setIsEditingFile, files }) => {
    console.log('o file selected', file)
    return (
        <Box display="flex" alignItems="center" width="100%">
            <FileListItem
                className="file-item"
                key={file.nome}
                style={{ width: '60%' }}
            >
                {isEditingFile.active && isEditingFile.index === index
                    ?
                    <Box display="flex" gap='1rem'>
                        <input className="inputFile" type="text" value={textFile} onChange={handleInputChangeFile} />
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => handleSaveClick(index)}
                        >
                            Salvar
                        </Button>
                    </Box>
                    :
                    <p>
                        <a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer">
                            {file.nome_label || file.name}
                        </a>
                    </p>
                }

                <Box display='flex' gap='1rem'>
                    {
                        isEditingFile.active && isEditingFile.index === index
                            ?
                            <Button
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                disableElevation
                                variant={"contained"}
                                size={"small"}
                                color={"error"}
                                onClick={() => setIsEditingFile(false)}
                            >
                                Cancelar
                            </Button>
                            :
                            <Button
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                disableElevation
                                variant={"contained"}
                                size={"small"}
                                color={"primary"}
                                onClick={() => handleEditClick(index)}
                            >
                                <EditIcon />
                            </Button>
                    }

                    <Button
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        disableElevation
                        variant={"contained"}
                        size={"small"}
                        color={"secondary"}
                        onClick={() => removeFile(index)}
                    >
                        <DeleteIcon />
                    </Button>
                </Box>
            </FileListItem>

            {index === 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '40%', paddingLeft: 4 }}>
                    <span>
                        {files.length > 0 && (
                            <Typography variant='h5'>
                                Clique em{' '}
                                <strong>
                                    Adicionar Declarações/Comprovantes{' '}
                                </strong>
                                novamente se houver outros documentos
                                a anexar.
                            </Typography>
                        )}
                    </span>
                </Box>
            )}
        </Box>
    )
}

export default FileItemSlim
